import { createStore } from 'vuex'
import { UserModule } from './user';
import { LoginModule } from './login';
import { PermissionModule } from './permission';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Login:LoginModule,
    User:UserModule,
    Permission:PermissionModule
  }
})
