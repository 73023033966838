<template>
  <div class="dashboard" >
    <div class="sidebaroverlay" :class="{'open' : menuState}"></div>
    <div class="sidebar" :class="{'open' : menuState}">
      <div class="syd-header">
        <div>
          <img src="@/assets/img/logo.png" class="logo-icon" alt="logo icon">
        </div>
        <div><h4 class="logo-text">OfficeOps</h4></div>
        <div class="mobile-toggle-icon ms-auto" @click="toggleMenu()">
          <svg fill="inherit" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 460.775 460.775" xml:space="preserve">
            <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
            c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
            c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
            c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
            l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
            c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
          </svg>
        </div>
      </div>
      <simplebar class="syd-nav">
        <ul class="navgroup">
          <li>
            <router-link  :to="{name : 'dashboard'}">
              <div v-html="icons.dashboard" class="p-icn"></div>
              <div class="menu-title">Dashboard</div>
            </router-link>
          </li>

          <div class="mgroup" ref="menuContainer">
            <li class="mlabel"> Team Management</li>
            <li v-if="hasPermission('Roles', 'view')">
              <router-link  :to="{name : 'roles'}">
                <div v-html="icons.roles" class="p-icn"></div>
                <div class="menu-title">Roles</div>
              </router-link>
            </li>
            <li v-if="hasPermission('Department', 'view')">
              <router-link  :to="{name : 'departments'}">
                <div v-html="icons.department" class="p-icn"></div>
                <div class="menu-title">Departments</div>
              </router-link>
            </li>
            <li v-if="hasPermission('Team', 'view')">
              <router-link  :to="{name : 'staff'}">
                <div v-html="icons.profile" class="p-icn"></div>
                <div class="menu-title">Staff Mgt.</div>
              </router-link>
            </li>
          </div>

          <div class="mgroup" ref="menuContainer">
            <li class="mlabel">
              Office Tools
            </li>
            <li>
              <a href="javascript:void(0)" class="has-arrow" >
                <div v-html="icons.task" class="p-icn"></div>
                <div class="menu-title">Task Manager</div>
              </a>
              <ul class="submenu">
                <li>
                  <router-link  :to="{name : 'taskoverview'}">
                    <div v-html="icons.circle" class="p-icn"></div>
                    <div class="menu-title">Task Overview</div>
                  </router-link>
                </li>
                <li>
                  <router-link  :to="{name : 'projectsview'}">
                    <div v-html="icons.circle" class="p-icn"></div>
                    <div class="menu-title">Projects</div>
                  </router-link>
                </li>
              </ul>
            </li>
          </div>
          
          <div class="mgroup" ref="menuContainer">
            <li class="mlabel">
              User Account
            </li>
            <li>
              <a href="javascript:void(0)" class="has-arrow" >
                <div v-html="icons.profile" class="p-icn"></div>
                <div class="menu-title">Profile</div>
              </a>
              <ul class="submenu">
                <li>
                  <router-link  :to="{name : 'profile', params: { profileId: user.username, page:'personal_info' }}">
                    <div v-html="icons.circle" class="p-icn"></div>
                    <div class="menu-title">Personal Information</div>
                  </router-link>
                </li>
                <li>
                  <router-link  :to="{name : 'profile', params: { profileId: user.username, page:'skills' }}">
                    <div v-html="icons.circle" class="p-icn"></div>
                    <div class="menu-title">Skills and Qualifications</div>
                  </router-link>
                </li>
                <li>
                  <router-link  :to="{name : 'profile', params: { profileId: user.username, page:'office_info' }}">
                    <div v-html="icons.circle" class="p-icn"></div>
                    <div class="menu-title">Office Information</div>
                  </router-link>
                </li>
                <li>
                  <router-link  :to="{name : 'profile', params: { profileId: 'df', page:'here' }}">
                    <div v-html="icons.circle" class="p-icn"></div>
                    <div class="menu-title">Notifications</div>
                  </router-link>
                </li>
                <li>
                  <router-link  :to="{name : 'profile', params: { profileId: 'df', page:'here' }}">
                    <div v-html="icons.circle" class="p-icn"></div>
                    <div class="menu-title">Security Settings</div>
                  </router-link>
                </li>
              </ul>
            </li>
          </div>
        </ul>
      </simplebar>
    </div>
  </div>
</template>
<script>
import icons from '@/assets/js/icons.js';
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import { useStore } from 'vuex';
import { usePermissions } from '@/composables/usePermissions';
import { ref, onMounted,computed, toRefs  } from 'vue';
export default {
  name: 'DashboardView',
  components:{simplebar},
  props: ['menu'],
  setup(props,ctx){
    const store= useStore();
    const user = computed(() => store.state.User.user);
    const svgIcons = ref(icons);
    const menuContainer = ref([]);
    const { menu } = toRefs(props);
    const menuState = computed(() => menu.value);
    const { hasPermission } = usePermissions();
    function toggleMenu(){
        ctx.emit("toggleMenu");
      }
    onMounted(() => {
      var hasArrowElements=document.querySelectorAll('.has-arrow');
      hasArrowElements.forEach((el) => {
        el.addEventListener("click",()=>{
          var submenu=el.parentNode.querySelector(".submenu");
          console.log(submenu)
          submenu.style.maxHeight="0px"
          submenu.classList.toggle("show")
          submenu.style.maxHeight="200px"
          if(el.getAttribute("aria-expanded")){
            el.removeAttribute('aria-expanded')
          }else{
            el.setAttribute('aria-expanded',"true")
          }
        })
      });
    });
    return {user, icons: svgIcons.value, menuContainer,menuState,toggleMenu,hasPermission };
  }
}
</script>
<style lang="css" scoped>
.sidebar{height:100vh;
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-right: 1px solid var(--bs-border-color);
  z-index: 11;
  box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;}

  .syd-header {
    width: 250px;
    height: 60px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 0 15px;
    z-index: 5;
    background-color: #fff;
}
.syd-header img{width:30px}
.syd-header .logo-text {
  font-size: 22px;
  margin-left: 10px;
  margin-bottom: 0;
  letter-spacing: 1px;
  color: #008cff;
}
.syd-header .mobile-toggle-icon {
  fill: #008cff;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.syd-header .mobile-toggle-icon:hover{background-color: #f2f9ff;
    color: #008cff;
}
.syd-header .mobile-toggle-icon svg{height:11px}

.syd-nav{height: calc(100vh - 60px);
  position: fixed; width:250px;
  top: 60px;}
.sidebar .navgroup, .sidebar ul{background: 0 0; margin: 0; list-style: none;
    display: flex; padding: 10px; flex-direction: column;}
.sidebar .navgroup li:first-child {margin-top: 5px;}
.sidebar .navgroup li {display: flex; flex-direction: column;
    position: relative; margin-top: 5px;}
.sidebar .navgroup a {position: relative;display: flex;
    align-items: center;justify-content: left; padding: 6px 16px;
    font-size: 14px; color: #151515; fill: #151515; stroke: #151515; outline-width: 0;
    text-overflow: ellipsis; overflow: hidden; letter-spacing: 0.5px;
    border-radius: 0.25rem; transition: all 0.3s ease-out;}
.sidebar .navgroup  .p-icn {width: 24px; height:24px; line-height: 1;
  display: flex; align-items: center; justify-content: center;}
.sidebar .navgroup a .menu-title {margin-left: 10px;}
.sidebar .navgroup .router-link-active , .sidebar .navgroup a:active, .sidebar .navgroup a:focus, .sidebar .navgroup a:hover {
  color: #008cff; fill: #008cff; stroke: #008cff;  text-decoration: none; 
  background-color: rgba(0, 140, 255, 0.05); font-size: 15px;}
.sidebar .mlabel {padding: 20px 15px 5px 5px; color: #b0afaf;
    text-transform: uppercase; font-size: 12px; letter-spacing: 0.5px;}
.sidebar .has-arrow:after {position: absolute; content: "";
  width: .5em; height: .5em; border-style: solid; border-width: 1px 0 0 1px; border-color: initial;right: 1em;
  transform: rotate(-45deg) translateY(-50%);transform-origin: top;
  top: 50%;transition: all .3s ease-out;}
.sidebar .has-arrow[aria-expanded=true]:after, .sidebar .router-link-active>.has-arrow:after {
    transform: rotate(-135deg) translateY(-50%);}
.sidebar .submenu{padding:0; font-size:13px}
.sidebar .submenu:not(.show) {display: none;}
.sidebar .submenu li a{font-size:14px}
.sidebar .submenu .p-icn{padding:5px}
.sidebar{-webkit-transition: -webkit-transform .3s cubic-bezier(.32, 1.25, .375, 1.15);
    -moz-transition: -moz-transform .3s cubic-bezier(.32, 1.25, .375, 1.15);
    -o-transition: -o-transform .3s cubic-bezier(.32, 1.25, .375, 1.15);
    transition: transform .3s cubic-bezier(.32, 1.25, .375, 1.15);}
.sidebar:not(.open){transform: translate(-250px,0);}
@media screen and (max-width: 700px) {
  .sidebar {transform: translate(-250px, 0px);}
  .sidebar:not(.open){transform: translate(0px,0);}
}
</style>
