import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex';
import Dashboard from '../views/DashboardView.vue'
import Profile from '../views/ProfileView.vue'
import Departments from '../views/DepartmentsView.vue'
import Roles from '../views/RolesView.vue'
import StaffMgt from '../views/StaffMgt.vue'
import ForbiddenView from '../views/ForbiddenView.vue'
import TaskOverview from '../views/TasksOverview.vue'
import ProjectsView from '../views/ProjectsView.vue'
import TasksView from '../views/TasksView.vue'
import MyTasksView from '../views/MyTaskView.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/profile/:profileId/:page',
    name: 'profile',
    component: Profile
  },
  {
    path: '/departments',
    name: 'departments',
    component: Departments
  },
  {
    path: '/roles',
    name: 'roles',
    component: Roles,
    meta: {
      requiresAuth: true,
      permission: { resource: 'Roles', action: 'view' }, // Permission needed
    }
  },
  {
    path: '/staff',
    name: 'staff',
    component: StaffMgt
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: ForbiddenView
  },
  {
    path: '/taskoverview',
    name: 'taskoverview',
    component: TaskOverview
  },
  {
    path: '/projectsview',
    name: 'projectsview',
    component: ProjectsView
  },
  {
    path: '/tasks/:project',
    name: 'tasksview',
    component: TasksView
  },
  {
    path: '/mytasks',
    name: 'mytasks',
    component: MyTasksView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const store = useStore(); // Access Vuex store

  // Ensure permissions are loaded
  if (!store.state.Permission.permissions || store.state.Permission.permissions.length === 0) {
    try {
      await store.dispatch('Permission/fetchPermissions'); // Fetch and transform permissions
    } catch (error) {
      console.error('Failed to fetch permissions:', error);
      return next('/dashboard'); // Redirect to login on failure
    }
  }

  // Check permissions if required
  if (to.meta.permission) {
    const { resource, action } = to.meta.permission;
    const hasPermission = store.getters['Permission/hasPermission'](resource, action);

    if (!hasPermission) {
      return next('/unauthorized'); // Redirect to unauthorized page
    }
  }

  next(); // Allow navigation if all checks pass
});

export default router
