<template>
    <div class="topnav" :class="{'open' : menuState}">
        <div class="topnav-in">
          <div class="toplft">
            <a href="javascript:void(0);" @click="toggleMenu()">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 18H10" stroke="inherit" stroke-width="2" stroke-linecap="round"/>
                <path d="M4 12L16 12" stroke="inherit" stroke-width="2" stroke-linecap="round"/>
                <path d="M4 6L20 6" stroke="inherit" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </a>
          </div>
          <div class="topryt">
            <div class="rytfst">
             <div class="rytfst-icons">
                <div class="dropdown v-flex align-items-center">
                    <div class="dp-pdd v-flex align-items-center dropdown-large">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                            <path d="M12 6.44v3.33M12.02 2C8.34 2 5.36 4.98 5.36 8.66v2.1c0 .68-.28 1.7-.63 2.28l-1.27 2.12c-.78 1.31-.24 2.77 1.2 3.25a23.34 23.34 0 0 0 14.73 0 2.22 2.22 0 0 0 1.2-3.25l-1.27-2.12c-.35-.58-.63-1.61-.63-2.28v-2.1C18.68 5 15.68 2 12.02 2Z" stroke="inherit" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"></path>
                            <path d="M15.33 18.82c0 1.83-1.5 3.33-3.33 3.33-.91 0-1.75-.38-2.35-.98-.6-.6-.98-1.44-.98-2.35" stroke="inherit" stroke-width="1.5" stroke-miterlimit="10"></path>
                        </svg>
                        <span>23</span>
                      </span>
                      <ul class="dropdown-menu">
                        <div class="dropdown-ttle">
                          <div class="v-flex align-items-center justify-content-between">
                            <div class="ttle">
                              Notifications
                            </div>
                            <div class="ttle-badge">
                              0 New
                            </div>
                          </div>
                        </div>
                        <div class="dropdown-cnt">
                          <a class="dropdown-item" href="javascript:;">
                            <div class="v-flex align-items-center">
                              <div class="usr-dp">
                                <img src="@/assets/img/avatar.png" class="msg-avatar" alt="user avatar">
                              </div>
                              <div class="flex-grow-1">
                                <h6 class="msg-name">Daisy Anderson<span class="msg-time float-end">5 sec
                              ago</span></h6>
                                <p class="msg-info">The standard chunk of lorem</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </ul>
                    </div>
                </div>
             </div>
            </div>
            <div class="rytlst">
              <div class="usr-box dropdown px-3">
                <span class="v-flex align-items-center usr-box-in gap-3">
                  <div class="usr-img">
                    <img :src="user.dp">
                  </div>
                  <div class="usr-info wbvw">
                    <p class="usr-name mb-0">{{user.name}}</p>
                    <p class="desgn mb-0">{{user.persona}}</p>
                  </div>
                </span>
                <ul class="dropdown-menu">
                  <div class="usr-drp">
                    <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="6" r="4" stroke="inherit" stroke-width="1.5"/>
                      <path d="M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634" stroke="inherit" stroke-width="1.5" stroke-linecap="round"/>
                      </svg>
                      <span>My Profile</span>
                  </div>
                  <div class="usr-drp">
                      <label class="vx-switch">
                        <input type="checkbox" :checked="isDark" @click="toggleDarkMode">
                        <span class="slider round"></span>
                      </label>
                      <span>Dark Mode</span>
                  </div>
                  <div class="usr-drp">
                    <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" transform="rotate(180)">
                        <g stroke-width="0"/>
                        <g stroke-linecap="round" stroke-linejoin="round"/>
                        <g> 
                            <path d="M17.4399 14.62L19.9999 12.06L17.4399 9.5" stroke="inherit" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> 
                            <path d="M9.76001 12.0601H19.93" stroke="inherit" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> 
                            <path d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4" stroke="inherit" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> 
                        </g>
                    </svg>
                    <span>Sign Out</span>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { ref,onMounted,computed, toRefs } from "vue";
import { useStore } from 'vuex';
export default {
  props: ['menu'],
  setup(props,ctx) {
    const { menu } = toRefs(props);
    const menuState = computed(() => menu.value);
    const store= useStore();
    const user = computed(() => store.state.User.user);
      const isDark = ref(false);
      const toggleDarkMode = () => {
          isDark.value = !isDark.value;
          document.body.classList.toggle("dark-mode", isDark.value);
          localStorage.setItem("user-dark-mode", isDark.value ? "true" : "false");
      };
      onMounted(() => {
          const storedDarkMode = localStorage.getItem("user-dark-mode");
          if (storedDarkMode !== null) {
              isDark.value = storedDarkMode === "true";
          } else {
              // Use system preference if no preference is stored
              isDark.value = window.matchMedia("(prefers-color-scheme: dark)").matches;
          }
          document.body.classList.toggle("dark-mode", isDark.value);
      });
      function toggleMenu(){
        ctx.emit("toggleMenu");
      }
      return{
          isDark,toggleDarkMode, user, toggleMenu,menuState
      }
  }
}
</script>