<template>
  <div class="main-content">
    <div  class="profileView">
      <div class="plft">
        <div class="plft-prf v-flex align-items-center justify-content-center">
          <div class="prf-wrap v-flex">
            <div class="prf-dpwrap">
              <div class="prf-dp v-flex align-items-center justify-content-center">
                <div class="dp">
                  <img :src="user.dp"/>
                </div>
              </div>
              <span class="badge text-center"> 
                12 
              </span>
            </div>
            <h3 class="username">{{user.name}}</h3>
            <p class="persona">{{user.persona}}</p>
          </div>
          <div class="prf-menu">
            <ul>
              <li>
                <router-link  
                  :to="{name : 'profile', params: { profileId: user.username, page:'personal_info' }}"
                  class="v-flex align-items-center"
                  :class="{'active':state.personalView}">
                  <div v-html="icons.profile" class="ic"></div>
                  <span>Personal Info</span>
                  <span class="aln"></span>
                </router-link>
              </li>
              <li>
                <router-link  :to="{name : 'profile', params: { profileId: user.username, page:'skills' }}"
                  class="v-flex align-items-center"
                  :class="{'active':state.profView}">
                  <div v-html="icons.skills" class="ic"></div>
                  <span>Skills and Qualifications</span>
                  <span class="aln"></span>
                </router-link>
              </li>
              <li>
                <router-link  :to="{name : 'profile', params: { profileId: user.username, page:'office_info' }}"
                  class="v-flex align-items-center"
                  :class="{'active':state.officeView}">
                  <div v-html="icons.profile" class="ic"></div>
                  <span>Office Info</span>
                  <span class="aln"></span>
                </router-link>
              </li>
              <li>
                <router-link  :to="{name : 'profile', params: { profileId: user.username, page:'payment_info' }}"
                  class="v-flex align-items-center"
                  :class="{'active':state.payView}">
                  <div v-html="icons.profile" class="ic"></div>
                  <span>Payment Info</span>
                  <span class="aln"></span>
                </router-link>
              </li>
              <li>
                <router-link  :to="{name : 'profile', params: { profileId: user.username, page:'account_settings' }}"
                  class="v-flex align-items-center"
                  :class="{'active':state.acctView}">
                  <div v-html="icons.profile" class="ic"></div>
                  <span>Account Settings</span>
                  <span class="aln"></span>
                </router-link>
              </li>
              <li>
                <router-link  :to="{name : 'profile', params: { profileId: user.username, page:'security' }}"
                  class="v-flex align-items-center"
                  :class="{'active':state.securityView}">
                  <div v-html="icons.profile" class="ic"></div>
                  <span>Security Settings</span>
                  <span class="aln"></span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pryt">
        <div class="text-center" v-if="!state.loaded">
          <img src="@/assets/img/ajldr.gif" style="width:100px"/>
        </div>
        <div class="pinfo" v-if="state.personalView && state.loaded">
          <div class="pinfo-abt">
            <div class="rhdr">
              <h2>About</h2>
              <span class="btn-primary" @click="state.editPersonalModal = !state.editPersonalModal">Edit</span>
            </div>
            <p> 
              {{profile.about}} 
            </p>
          </div>
          <div class="divider v-flex align-items-center justify-content-center"></div>
          <div>
            <h2>My Information</h2>
            <ul>
              <li>
                <span>Name</span>
                <span>{{profile.name}}</span>
              </li>
              <li>
                <span>Date of Birth</span>
                <span>{{profile.dob}}</span>
              </li>
              <li>
                <span>Gender</span>
                <span>{{profile.gender}}</span>
              </li>
              <li>
                <span>Religion</span>
                <span>{{profile.religion}}</span>
              </li>
              <li>
                <span>Phone</span>
                <span>{{profile.phone}}</span>
              </li>
              <li>
                <span>Work Email</span>
                <span>{{profile.email}}</span>
              </li>
              <li>
                <span>Personal Email</span>
                <span>{{profile.pemail}}</span>
              </li>
              <li>
                <span>State of origin</span>
                <span>{{profile.state}}</span>
              </li>
              <li>
                <span>Local Government Area</span>
                <span>{{profile.lga}}</span>
              </li>
              <li>
                <span>Home Address</span>
                <span>{{profile.address}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="skils" v-if="state.profView && state.loaded">
          <div class="pinfo-abt" >
            <div class="rhdr">
              <h2>Certifications</h2>
              <span class="btn-primary" @click="state.addCertificationModal = !state.addCertificationModal">Add </span>
            </div>
            <div class="certs">
              <div class="cert">
                <div>Degree</div>
                <div>Institution</div>
                <div>Year</div>
                <div></div>
              </div>
              <div class="cert" v-for="(cert,idx) in state.certs" :key="idx">
                <div> {{cert.degree}}</div>
                <div> {{ cert.institution }} </div>
                <div> {{ cert.year }} </div>
                <div> <div v-html="icons.trash" class="ic" @click="deleteCert(idx)"></div></div>
              </div>
            </div>
          </div>
          <div class="pinfo-abt">
            <div class="rhdr">
              <h2>Professional Memberships</h2>
              <span class="btn-primary" @click="state.addMembershipModal = !state.addMembershipModal">Add </span>
            </div>
            <div class="certs">
              <div class="cert" v-for="(mem,idx) in state.pmems" :key="idx">
                <div> {{mem.name}}</div>
                <div> {{ mem.date }} </div>
                <div> 
                  <div v-html="icons.trash" class="ic" @click="deleteMem(idx)"></div>
                  <div v-html="icons.edit" class="ic" style="stroke:var(--primary-color)" @click="editMem(idx)"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="pinfo-abt">
            <div class="rhdr">
              <h2>Skills</h2>
              <span class="btn-primary" @click="state.addSkillsModal = !state.addSkillsModal">Add </span>
            </div>
            <div class="certs">
              <div class="skill" v-for="(skill,idx) in state.skills" :key="idx">
                <div class="sk_ctr">
                  <span>{{skill.name}}</span>
                  <div v-html="icons.trash" class="ic" @click="deleteSkill(idx)"></div>
                </div>
                <div class="s_ctn">
                  <div class="s_prg" :style="{ width: skill.level + '%',background: colors[idx % colors.length]}">{{skill.level}}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="offinfo" v-if="state.officeView && state.loaded">

        </div>
        <div class="payinfo" v-if="state.payView && state.loaded">

        </div>
        <div class="acctinfo" v-if="state.acctView && state.loaded">

        </div>
        <div class="security" v-if="state.acctinfo && state.loaded">

        </div>
      </div>
    </div>
  </div>

  <ModalView
    :isVisible="state.editPersonalModal"
    :fixclose="true"
    @close="state.editPersonalModal = false">

    <DynamicForm :fields="formFields" @submit="updatePersonal" :submitting="state.submitting" :errors="state.errors" :success="state.success" />
  </ModalView>

  <ModalView
    :isVisible="state.addCertificationModal"
    :fixclose="true"
    @close="state.addCertificationModal = false">
    <div class="rhdr" style="padding:20px 10px">
      <span class="btn-primary" @click="addCertRow()">Add more</span>
    </div>
    <DynamicForm ref="certForm" :columns="3" @sendData="updateCertField"
      :fields="CertFields" @submit="addCertificate" 
      :submitting="state.submitting" :errors="state.errors" 
      :success="state.success" />
  </ModalView> 

  <ModalView
    :isVisible="state.addMembershipModal"
    :fixclose="true"
    @close="state.addMembershipModal = false">
    <div class="rhdr" style="padding:20px 10px">
      <span class="btn-primary" @click="addMemRow()">Add more</span>
    </div>
    <DynamicForm ref="memForm" :columns="2" @sendData="updateMemField"
      :fields="memFields" @submit="addMembership" 
      :submitting="state.submitting" :errors="state.errors" 
      :success="state.success" />
  </ModalView> 

  <ModalView
    :isVisible="state.editMembershipModal"
    :fixclose="true"
    @close="state.editMembershipModal = false">
    <DynamicForm :columns="3"
      :fields="editMemField" @submit="updateMembership" 
      :submitting="state.submitting" :errors="state.errors" 
      :success="state.success" />
  </ModalView> 

  <ModalView
    :isVisible="state.addSkillsModal"
    :fixclose="true"
    @close="state.addSkillsModal = false">
    <div class="rhdr" style="padding:20px 10px">
      <span class="btn-primary" @click="addSkillsRow()">Add more</span>
    </div>
    <DynamicForm ref="skillsForm" :columns="2" @sendData="updateSkillsField"
      :fields="SkillsFields" @submit="addSkill" 
      :submitting="state.submitting" :errors="state.errors" 
      :success="state.success" />
  </ModalView> 
</template>

<script>
import icons from '@/assets/js/icons.js';
import { computed, defineComponent, ref, reactive, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import call from "../composables/call.js";
import { urls } from "../assets/js/urls";
import ModalView from '@/components/ModalView.vue';
import DynamicForm from '@/components/DynamicForm.vue';

export default defineComponent({
  name: 'ProfileView',
  components:{ModalView,DynamicForm },
  setup(){
    const store= useStore();
    const user = computed(() => store.state.User.user);
    const route = useRoute();
    const profile_id = computed(() => route.params.profileId)
    const page = computed(() => route.params.page)
    const svgIcons = ref(icons);
    const certForm=ref(null)
    const memForm=ref(null)
    const skillsForm=ref(null)
    const colors=["var(--primary-color)","#15ca20","#0dcaf0","#6627f1"];
    const state=reactive({personalView:false,officeView:false,payView:false,submitting:false,success:false,
      acctView:false,securityView:false,profView:false, loaded:false, editPersonalModal:false,errors:[],
      addCertificationModal:false,submitReady:true,submit:false,certs:[],addMembershipModal:false,pmems:[],
      addSkillsModal:false,skills:[]
    });
    const profile=reactive({name:"",dob:"",gender:"",religion:"",
      phone:"",email:"",address:"", about:"",pemail:"",state:"",lga:""
    });

    const CertFields = reactive([
      { name: "Add Certification", number: "1",data: 
        [{ type: 'text', name: 'institution_1', label: 'Institution', placeholder: 'Institution', 
          value: ""  },
          { type: 'text', name: 'degree_1', label: 'Degree', placeholder: 'Degree', 
          value: ""  },
          { type: 'date', name: 'year_1', label: 'Date Awarded', placeholder: 'Date Awarded', 
          value: "" }
        ]
      }
    ])

    const SkillsFields = reactive([
      { name: "Add Skill", number: "1",data: 
        [{ type: 'text', name: 'skill_1', label: 'Name of Skill', placeholder: 'Name of Skill', 
          value: ""  },
          { type: 'number', name: 'level_1', label: 'Expertise Level (%)', placeholder: 'Expertise Level (%)', 
          value: ""  }
        ]
      }
    ])

    const editMemField=reactive([]);

    function editMem(i){
      editMemField.length=0;
      var mem=state.pmems[i];
      editMemField.push(
        { name: "Edit Membership", number: "1",data: 
          [{ type: 'text', name: 'name', label: 'Name ', placeholder: 'Name', 
            value: mem.name  },
            { type: 'date', name: 'join', label: 'Join Date', placeholder: 'Join Date', 
            value: mem.jdate  },
            { type: 'date', name: 'exit', label: 'Exit Date', placeholder: 'Exit Date', 
            value: mem.ldate  },
            { type: 'hidden', name: 'id', label: 'Exit Date', placeholder: 'Exit Date', 
            value: mem.id  }
          ]
        }
      )
      state.editMembershipModal =true;

    }

    const memFields = reactive([
      { name: "Add Membership", number: "1",data: 
        [{ type: 'text', name: 'name_1', label: 'Name of Club, Group, etc', placeholder: 'Name of Club, Group, Institution, etc', 
          value: ""  },
          { type: 'date', name: 'join_1', label: 'Join Date', placeholder: 'Join Date', 
          value: ""  }
        ]
      }
    ])

    const formFields = reactive([
    {name: "Basic Overview", number: "1",data: 
      [{ type: 'textarea', name: 'about', label: 'About you', placeholder: 'A short text about yourself', 
        value: profile.about },
      ]
    },
    { 
      name: "Basic Information", 
      number: "2",
      data: [
        { type: 'text', name: 'name', label: 'Name (Last name first name)', value: profile.name },
        { type: 'date', name: 'dob', label: 'Date of Birth', value: profile.dob, placeholder: 'Date of Birth' },
        { type: 'radio', name: 'gender', label: 'Gender', value: profile.gender, placeholder: 'Your Gender',
          values: [{ label: "Male", value: "male" }, { label: "Female", value: "female" }]
        },
        { type: "text", name: "religion", label: "Religion", placeholder: "Religion", value: profile.religion },
        { type: "text", name: "phone", label: "Phone Number", placeholder: "Phone Number", value: profile.phone },
        { type: "email", name: "email", label: "Work Email Address", placeholder: "Work Email Address", value: profile.email },
        { type: "email", name: "pemail", label: "Personal Email Address", placeholder: "Personal Email Address", value: profile.pemail },
        { type: "text", name: "state", label: "State of origin", placeholder: "State of Origin", value: profile.state },
        { type: "text", name: "lga", label: "Local Government Area", placeholder: "Local Government Area", value: profile.lga },
        { type: "text", name: "address", label: "Home Address", placeholder: "Home Address", value: profile.address },
      ]
    }
  ]);

    const updatePersonal = (f) => {
      state.errors=[];
      const data={
        "updatePersonalInfo":"",
        about:f.about,
        name:f.name,
        dob:f.dob,
        gender:f.gender,
        religion:f.religion,
        phone:f.phone,
        email:f.email,
        pemail:f.pemail,
        address:f.address,
        state:f.state,
        lga:f.lga
      }
      state.submitting=true;
      call({
        url:urls.profile,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            var pinfo=f;
            profile.name=pinfo.name;
            profile.address=pinfo.address;
            profile.email=pinfo.email;
            profile.pemail=pinfo.pemail;
            profile.phone=pinfo.phone;
            profile.gender=pinfo.gender;
            profile.religion=pinfo.religion;
            profile.dob=pinfo.dob;
            profile.about=pinfo.about;
            profile.state=pinfo.state;
            profile.lga=pinfo.lga;
            state.success=true;
          }else{
            setErrors(res)
          }
        }
      })
    };

    onMounted(()=>{getPage();})

    function setTabs(){
      state.success=false;
      state.submitting=false;
      state.errors=[];
      state.personalView=false;
      state.officeView=false;
      state.payView=false;
      state.acctView=false;
      state.securityView=false;
      state.profView=false;
      switch(page.value){
        case "office_info":
          state.officeView=true;
          break;
        case "payment_info":
          state.payView=true;
          break;
        case "account_settings":
          state.acctView=true;
          break;
        case "security":
          state.securityView=true;
          break;
        case "skills":
          state.profView=true;
          break;
        default: 
          state.personalView=true;
          break;
      }
    }

    watch(
      () => route.params.page,
      (newPage) => {
       console.log(newPage);
       setTabs()
      }
    );

    function getPage(){
      setTabs()
      call({
        url:urls.profile,
        data:{getProfile:profile_id.value},
        success:(res)=>{
          profile.processing=false;
          if(res.status){
            state.loaded=true
            var pinfo=res.msg.pinfo;
            profile.name=pinfo.name;
            profile.address=pinfo.address;
            profile.email=pinfo.email;
            profile.pemail=pinfo.pemail;
            profile.phone=pinfo.phone;
            profile.gender=pinfo.gender;
            profile.religion=pinfo.religion;
            profile.dob=pinfo.dob;
            profile.about=pinfo.about;
            profile.state=pinfo.state;
            profile.lga=pinfo.lga;
            state.certs=res.msg.certs;
            state.pmems=res.msg.pmems;
            state.skills=res.msg.skills;
            for(var i=0; i<formFields.length; i++){
              formFields[i].data.forEach(field => {
              if (profile[field.name]) {
                  field.value = profile[field.name];
                }
              });
            }
          }else{
            alert(res.msg)
          }
        }
      })
    }

    function addCertRow(){
      certForm.value.sendData();
    }

    function addMemRow(){
      memForm.value.sendData();
    }

    function addSkillsRow(){
      skillsForm.value.sendData();
    }

    function setErrors(res){
      var errors=[];
      Object.keys(res.msg).forEach(key => {
        var e=res.msg[key],er="";
        Object.keys(e).forEach(k => {
          er+=er=="" ? e[k] : ", "+e[k]
        })
        errors.push(er);
      });
      state.errors=errors;
    }

    function updateCertField(f){
      for(var i=0; i<CertFields.length; i++){
        CertFields[i].data.forEach(field => {
        if (f[field.name]) {
            field.value = f[field.name];
          }
        });
      }
      CertFields.push(
        { name: "Add Certification", number:(CertFields.length + 1).toString(),
      data: 
        [{ type: 'text', name: 'institution_'+(CertFields.length + 1).toString(), label: 'Institution', placeholder: 'Institution', 
          value: ""  },
          { type: 'text', name: 'degree_'+(CertFields.length + 1).toString(), label: 'Degree', placeholder: 'Degree', 
          value: ""  },
          { type: 'date', name: 'year_'+(CertFields.length + 1).toString(), label: 'Date Awarded', placeholder: 'Date Awarded', 
          value: ""  }
        ]
      })
    }

    function updateMemField(f){
      for(var i=0; i<memFields.length; i++){
        memFields[i].data.forEach(field => {
        if (f[field.name]) {
            field.value = f[field.name];
          }
        });
      }
      memFields.push(
        { name: "Add Membership", number: (memFields.length + 1).toString(),
          data:[
            { type: 'text', name: 'name_'+(memFields.length + 1).toString(), label: 'Name of Club, Group, etc', placeholder: 'Name of Club, Group, Institution, etc', 
            value: ""  },
            { type: 'date', name: 'join_'+(memFields.length + 1).toString(), label: 'Join Date', placeholder: 'Join Date', 
            value: ""  }
          ]
        }
      )
    }

    function updateSkillsField(f){
      for(var i=0; i<SkillsFields.length; i++){
        SkillsFields[i].data.forEach(field => {
        if (f[field.name]) {
            field.value = f[field.name];
          }
        });
      }
      SkillsFields.push(
        { name: "Add Skill", number: (SkillsFields.length + 1).toString(),
          data:[
            { type: 'text', name: 'skill_'+(SkillsFields.length + 1).toString(), label: 'Name of Skill', placeholder: 'Name of Skill', 
            value: ""  },
            { type: 'number', name: 'level_'+(SkillsFields.length + 1).toString(), label: 'Expertise Level (%)', placeholder: 'Expertise Level (%)', 
            value: ""  }
          ]
        }
      )
    }

    function addCertificate(data){
      state.submitting=true;
      state.errors=[];
      data.addCertificate=(CertFields.length + 1).toString();
      call({
        url:urls.profile,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
            state.certs=res.msg;
          }else{
            setErrors(res)
          }
        }
      })
    }

    function addMembership(data){
      state.submitting=true;
      state.errors=[];
      data.addMembership=(memFields.length + 1).toString();
      call({
        url:urls.profile,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
            state.pmems=res.msg;
          }else{
            setErrors(res)
          }
        }
      })
    }

    function deleteCert(i){
      var cert=state.certs[i];
      var c=confirm("Are you sure you want to delete this item?");
      if(!c){return}
      state.loaded=false
      call({
        url:urls.profile,
        data:{deleteCert:cert.id},
        success:(res)=>{
          state.loaded=true;
          if(res.status){
            delete state.certs[i];
          }else{
            alert(res.msg)
          }
        }
      })
    }

    function deleteMem(i){
      var mem=state.pmems[i];
      var c=confirm("Are you sure you want to delete this item?");
      if(!c){return}
      state.loaded=false
      call({
        url:urls.profile,
        data:{deleteMem:mem.id},
        success:(res)=>{
          state.loaded=true;
          if(res.status){
            delete state.pmems[i];
          }else{
            alert(res.msg)
          }
        }
      })
    }

    function deleteSkill(i){
      var s=state.skills[i];
      var c=confirm("Are you sure you want to delete this item?");
      if(!c){return}
      state.loaded=false
      call({
        url:urls.profile,
        data:{deleteSkill:s.id},
        success:(res)=>{
          state.loaded=true;
          if(res.status){
            delete state.skills[i];
          }else{
            alert(res.msg)
          }
        }
      })
    }

    function updateMembership(data){
      state.submitting=true;
      state.errors=[];
      data.updateMembership="";
      call({
        url:urls.profile,
        data:data,
        success:(res)=>{
          editMemField.length=0;
          state.submitting=false;
          if(res.status){
            state.success=true;
            state.pmems=res.msg;
          }else{
            setErrors(res)
          }
        }
      })
    }

    function addSkill(data){
      state.submitting=true;
      state.errors=[];
      data.addSkill=(SkillsFields.length + 1).toString();
      call({
        url:urls.profile,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
            state.skills=res.msg;
          }else{
            setErrors(res)
          }
        }
      })
    }

    return{profile, profile_id,page,user,icons: svgIcons.value, state,getPage,deleteCert,
      formFields,updatePersonal,CertFields,addCertRow,addCertificate,certForm,updateCertField,
      memFields,memForm,addMemRow,updateMemField,addMembership,deleteMem,editMemField,editMem,
      updateMembership,SkillsFields,skillsForm,addSkillsRow,updateSkillsField,addSkill,colors,
      deleteSkill
    }
  }
})
</script>


<style lang="css" scoped>
  .profileView{background:var(--white-color);width: min(100%, 960px);
    display: flex; margin: 10px auto;}
  .plft{width: min(30%, 240px); border-right: 1px solid #dfe6e9;}
  .plft-prf{margin:10px 0px; display:flex; flex-direction: column;
    min-height: 530px;}
  .prf-wrap{flex-direction: column; align-items: center;
    padding: 50px 0;}
  .prf-dpwrap{position:relative;}
  .prf-dp{border-radius: 50%;  width:80px; height: 80px; background: #f0f3f5;
    }
  .prf-dp:before{content: ""; position: absolute; background: conic-gradient(#008cff, hsla(0, 0%, 100%, 0));
  border-radius: 100%; top:-7px; left:-7px; right:-7px; bottom:-7px}
  .prf-dp:after{left: -3px; right: -3px; background: #fff;
    border-radius: 100%; content: ""; position: absolute;
    top: -3px; bottom: -3px; pointer-events: none;}
  .prf-dp .dp{overflow: hidden; width:80px; height:80px; border-radius: 50%;
    position: relative; z-index: 1;}
  .prf-dpwrap .badge{bottom: -7px;top: auto; height: 16px;line-height: 16px; z-index: 1;
    position:absolute; right:4px; padding: 0 4px; display: inline-block;font-size: .75rem; font-weight: 400; 
    color:var(--white-color); white-space: nowrap; vertical-align: baseline; background:#008cff; border-radius: 10rem;}
  .prf-wrap .username{margin-top: 24px; margin-bottom: 4px; font-size: 20px; text-align: center;
    line-height: 1em;}
  .prf-wrap .persona{font-size: 14px; line-height: 21px; color: #636e72;}
  .prf-menu{width:100%}
  .prf-menu ul{padding-left:0; margin-bottom: 0; margin-top:0;}
  .prf-menu ul li{margin-bottom: 16px !important; margin-top:4px !important; 
  list-style: none;}
  .prf-menu ul li a{padding:12px 24px !important; font-size: 14px; color:var(--black);
    stroke:var(--black); position:relative;}
  .prf-menu ul li a .ic{width:18px; height:18px; margin-right:10px; display: flex;
    align-items: center; justify-content: center;}
  .prf-menu ul li a .ic svg{stroke-width: 0.4 !important;}
  .prf-menu ul li a .aln{position:absolute; right:0; width:2px; top:0; 
  height:100%; background: var(--primary-color); opacity:0;}
  .prf-menu ul li a.active{background: #e6f4ff; color:var(--primary-color);
  stroke: var(--primary-color);}
  .prf-menu ul li a.active .aln{opacity:1}

  .pryt{flex-grow: 1;flex-basis: 0;}
  .pinfo,.skils{padding:20px;}
  .pinfo h2, .skils h2{font-family: popins; font-size: 24px; line-height: 28px;
    color: #2d3436; margin-bottom: 14px;}
  .pinfo-abt{padding:20px 0;}
  .pinfo-abt:first-child{padding-top:0}
  .pinfo-abt p{width:80%; font-size: 14px; 
    line-height: 21px; color: #636e72;}
  .pinfo .divider{text-align: center; margin: 24px 16px;
    border-color: #dfe6e9;
    white-space: nowrap; color: #2d3436;
    font-size: 14px; line-height: 1.5;}
  .divider:after, .divider:before {content: ""; display: block;
      width: 50%; border-top: 1px solid transparent; border-top-color: inherit;}
  .pinfo ul{padding: 0; list-style: none; margin: 10px 0;}
  .pinfo ul li{display: flex; align-items: center;}
  .pinfo ul li span:first-child{min-width:200px;}
  .pinfo ul li span:last-child{flex-grow: 1}
  .pinfo ul li span{padding:10px; font-size: 14px; color: #636e72;}
  .rhdr{display:flex; align-items: center; justify-content: space-between}
  .certs{margin:20px 0}
  .cert{display:flex; align-items: center; width: 100%; justify-content: space-between;}
  .cert > div{flex: 1; padding: 10px;}
  .cert > div:last-child{display:flex; align-items: center; max-width:100px}
  .cert:nth-child(odd){background: #ebebeb;}
  .certs .ic{height: 25px; fill: red; stroke:red; cursor: pointer; padding: 4px; display: flex; 
    align-items: center; justify-content: center;}
  .s_ctn {width: 100%; background-color: #ddd;border-radius: 50px; overflow: hidden;}
  .s_ctn .s_prg {text-align: right; padding-top: 2px; padding-bottom: 2px; color: white;
    font-size: 10px; font-weight: bold; padding-right: 5px;}
  .certs .skill{margin-bottom:20px}
  .skill .sk_ctr{display: flex; align-items: center; padding: 5px 0;}
  .skill .sk_ctr span{font-size: 14px; color: var(--text-color);}
</style>
