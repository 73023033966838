<template>
    <div class="card temp1" v-if="template=='1' ">
        <div class="card-in">
            <div class="hd-ctr">
                <div class="v-flex align-items-center">
                    <h3> {{ page.title }} </h3>
                    <div v-html="icons.info" class="ic" @mouseenter="toggleExpand(true)"
                        @mouseleave="toggleExpand(false)" @click="toggleExpand(!isExpanded)"></div>
                </div>
                <span class="btn-primary" @click="sendAction" v-if="page.btn!=''"> {{ page.btn }}</span>
            </div>
            <div class="hd-dsc" :style="descriptionStyle">
                {{ page.desc }}
            </div>
        </div>
    </div>
    <div v-else-if="template=='2'" class="temp2 v-flex align-items-center justify-content-between">
      <div>
        <h3 class="mb-0 h4 font-weight-bolder">{{ page.title }}</h3>
        <p class="mb-4">
          {{ page.desc }}
        </p>
      </div>
      <span class="btn-primary" @click="sendAction" v-if="page.btn!=''"> {{ page.btn }}</span>
    </div>
  </template>
  
  <script>
  import icons from '@/assets/js/icons.js';
  import { defineComponent, ref, computed} from 'vue';
  
  export default defineComponent({
    name: 'PageHeader',
    props: {
      page: Object,
      template: {
        type: String,
        default:"1"
      }
    },
    setup(props, ctx){
      const svgIcons = ref(icons);
      const isExpanded = ref(false);  
      const toggleExpand = (expand) => {
        isExpanded.value = expand;
      };

      function sendAction(){
        ctx.emit("action")
      }
  
      // Dynamically calculate the style for the description based on expand state
      const descriptionStyle = computed(() => ({
        maxHeight: isExpanded.value ? '100px' : '0',
        transition: 'max-height 0.3s ease, color 0.3s ease', // Smooth transition for height and color
        overflow: 'hidden', // Hide content when height is 0
      }));
      return{icons: svgIcons.value, toggleExpand, descriptionStyle,sendAction}
    }
  })
  </script>
  
<style scoped>
    .temp1 .hd-dsc{font-size: 12px; padding: 0;}
    .temp1 .hd-ctr{display: flex; align-items: center; justify-content:space-between;}
    .temp1 .hd-ctr h3{margin: 0;}
    .temp1 .hd-ctr .ic{width: 17px; height: 17px; fill: var(--primary-color); margin-left: 10px;
        line-height: 0; cursor:pointer;}
    .temp1 .btn-primary{margin: 5px 0;}
    .temp2{padding: 0 0.75rem;}
    .temp2 h3{font-weight: 700 !important; letter-spacing: -0.05rem;     font-size: 1.5rem;
      line-height: 1.375; color: var(--primary-color); font-family: Inter, Helvetica, Arial, sans-serif}
    .temp2 p{margin-bottom: 1.5rem !important; line-height: 1.625; font-size:1rem; font-weight: 400;
      font-family: Inter, Helvetica, Arial, sans-serif; color:#262626}
    @media screen and (max-width: 700px) {
      .temp1 .hd-ctr{flex-wrap: wrap}
      .temp2{flex-wrap: wrap;}
      .temp2 > div{width:100%}
      .temp2 p{margin-bottom: 10px !important;}
    }
</style>