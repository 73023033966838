// src/composables/useDarkMode.js
import { ref, onMounted, watch } from "vue";
import { useDark } from "@vueuse/core";

export function useDarkMode(usr) {
if(usr!="sys"){localStorage.setItem("user-dark-mode", usr );}
else{localStorage.removeItem("user-dark-mode");}
  const isUserPreferenceSet = localStorage.getItem("user-dark-mode") !== null;
  const systemPrefersDark = useDark(); // Reactively follow the system preference

  // Set darkMode based on user preference or system setting
  const darkMode = ref(
    isUserPreferenceSet
      ? localStorage.getItem("user-dark-mode") === "true"
      : systemPrefersDark.value
  );

  // Apply dark mode to the body class
  const applyDarkMode = () => {
    document.body.classList.toggle("dark-mode", darkMode.value);
  };

  // Initialize dark mode on mounted
  onMounted(() => {
    applyDarkMode();
  });

  // Watch for changes in darkMode and update localStorage
  watch(darkMode, (newVal) => {
    if (newVal !== "sys") {
      localStorage.setItem("user-dark-mode", newVal ? "true" : "false");
    } else {
      localStorage.removeItem("user-dark-mode");
    }
    applyDarkMode(); // Update body class
  });

  // Watch system preference only if user preference is not set
  if (!isUserPreferenceSet) {
    watch(systemPrefersDark, (newVal) => {
      darkMode.value = newVal;
    });
  }

  return { darkMode, applyDarkMode };
}
