import { urls } from "../assets/js/urls";

export const PermissionModule = {
  namespaced: true,

  state: {
    permissions: null, // Initialize as `null` to distinguish between "not loaded" and "empty"
  },

  mutations: {
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
  },

  actions: {
    async fetchPermissions({ state, commit }) {
      // Check if permissions are already loaded
      if (state.permissions) {
        console.log('Permissions already loaded, skipping fetch.');
        return;
      }

      const abortController = new AbortController();
      const url = urls.dashboard;
      const data = {getPerm: "getPerm"}; // Adjust payload as needed

      const request = new Request(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
        signal: abortController.signal,
      });

      try {
        const response = await fetch(request);

        if (!response.ok) {
          throw new Error(`Failed to fetch permissions: ${response.statusText}`);
        }

        const json = await response.json();
        console.log(json)

        // Transform the permissions
        const permissions = transformPermissions(json);

        // Commit to state
        commit('SET_PERMISSIONS', permissions);
      } catch (error) {
        console.error('Error fetching permissions:', error);
        throw error; // Let the caller handle errors
      }
    },
  },

  getters: {
    hasPermission: (state) => (resource, action) => {
      if (!state.permissions) return false; // If permissions aren't loaded, deny access
      return state.permissions.some(
        (perm) => perm.name === resource && perm.type === action
      );
    },
  },
};

// Helper function to transform permissions JSON
function transformPermissions(permissionsJson) {
  const transformed = [];
  Object.keys(permissionsJson).forEach((resource) => {
    const actions = permissionsJson[resource];
    Object.keys(actions).forEach((action) => {
      if (actions[action]) {
        transformed.push({ name: resource, type: action });
      }
    });
  });
  return transformed;
}

