<template>
  <div class="template_1">
    <!-- <form @submit.prevent="handleSubmit"> -->
      <fieldset v-for="(set, setIndex) in fields" :key="setIndex">

        <legend>
          <span class="number">{{set.number}}</span>
          <span class="desc">
            <p class="ltitle">{{set.name}}</p>
            <p class="dtxt" v-if="set.desc != ''"> {{ set.desc }} </p>
          </span>
        </legend>

        <div class="temp-row" v-for="(row, rowIndex) in fieldRows[setIndex]" :key="rowIndex">
          <div class="temp-input" 
            v-for="(field, index) in row" 
            :key="index" :style="{ flex: 1 }"
            :class="{'temp1-textarea' : field.type === 'textarea'}">
  
            <label :for="field.name" v-if="!['hidden'].includes(field.type)">{{ field.label }}</label>
  
            
            <input
              v-if="['text', 'number', 'email','hidden','password'].includes(field.type)"
              :type="field.type"
              :name="field.name"
              v-model="formData[field.name]"
              :id="field.name"
            />

            <CalendarView
              iclass="input"
              v-if="['date'].includes(field.type)"
              v-model="formData[field.name]"
            />

            <textarea
              v-else-if="field.type === 'textarea'"
              :name="field.name"
              v-model="formData[field.name]"
              :id="field.name"
              rows="2" cols="80"
            ></textarea>
  
            <select
              v-else-if="field.type === 'select'"
              :name="field.name"
              v-model="formData[field.name]"
              :id="field.name"
            >
              <option v-for="(option, idx) in field.options" :key="idx" :value="option.value">
                {{ option.label }}
              </option>
            </select>

            <select
              v-else-if="field.type === 'selectGroup'"
              :name="field.name"
              v-model="formData[field.name]"
              :id="field.name"
            >
              <optgroup v-for="(group, idx) in field.options" :key="idx" :label="group.label">
                <option v-for="(option, idxx) in group.options" :key="idxx" :value="option.value">
                  {{ option.label }}
                </option>
              </optgroup>
            </select>
  
            <div v-else-if="field.type === 'checkbox'">
              <div v-for="(option, idx) in field.values" :key="idx">
                <input 
                  type="checkbox"
                  :name="field.name"
                  v-model="formData[field.name]"
                  :value="option.value"
                />
                <label :for="option.label" class="light">{{option.label}}</label>
              </div>
            </div>
  
            <div 
              v-else-if="field.type === 'radio'">
              <div v-for="(option, idx) in field.values" :key="idx">
                <input 
                  type="radio"
                  :name="field.name"
                  v-model="formData[field.name]"
                  :value="option.value"
                />
                <label :for="option.label" class="light">{{option.label}}</label>
              </div>
            </div>
            
            
          </div>
        </div>
      </fieldset>

      <div class="errors" v-if="!submitting">
        <p v-for="(e,i) in errors" :key="i">{{e}}</p>
      </div>
      
      <div class="temp-row submit-btn " v-if="!submitting && !success"
      :class="{'align-items-center justify-content-between': addMore !=''}">
      <div class="btn-primary" @click="sendData" v-if="addMore !=''"> {{ addMore }} </div>
        <button 
          @click="handleSubmit" 
          :style="addMore !='' ? {width: 'auto', margin: '0', padding: '.375rem 24px'} : {}"
        >Submit</button>
      </div>
      <div class="temp-row success" v-if="success" style="justify-content: center">
        <p>Form upload successful</p>
      </div>
      <div class="temp-row submit-btn" v-if="submitting" >
        <img src="@/assets/img/ajldr.gif" style="width:100px"/>
      </div>
  </div>
</template>
  
  
<script>
import { ref, computed, defineComponent, onMounted, watchEffect } from 'vue';
import CalendarView from "@/components/CalendarView.vue";

export default defineComponent({
  components: { CalendarView },
  props: {
    fields: {
      type: Array,
      required: true
    },
    columns: {
      type: Number,
      default: 1 // Number of columns per row
    },
    submitting:{
      type:Boolean,
      default:false,
    },
    success:{
      type:Boolean,
      default:false,
    },
    errors:{
      type:Array
    },
    addMore:{
      type:String,
      default:""
    }
  },
  setup(props, { emit }) {
    const formData = ref({}); // To hold form data

    // Helper function to initialize or reinitialize form data
    const initializeFormData = () => {
      const data = {};
      props.fields.forEach(set => {
        set.data.forEach(field => {
          data[field.name] = field.value || ''; // Assign initial values or empty string
        });
      });
      formData.value = data; // Update formData
    };

    // Initialize form data on mount
    onMounted(initializeFormData);

    // Use watchEffect to reactively update formData when fields prop changes
    watchEffect(() => {
      console.log("fields updated", props.fields); // Log to confirm changes
      initializeFormData(); // Reinitialize form data when fields change
    });

    // Computed property for splitting fields into rows based on columns
    const fieldRows = computed(() => {
      const rows = [];
      props.fields.forEach(set => {
        const rowsForSet = [];
        for (let i = 0; i < set.data.length; i += props.columns) {
          rowsForSet.push(set.data.slice(i, i + props.columns));
        }
        rows.push(rowsForSet);
      });
      return rows;
    });

    // Handle form submission
    const handleSubmit = () => {
      emit('submit', { ...formData.value}); // Emit form data to parent component
    };

    const sendData = () => {
      emit('sendData', { ...formData.value});
    };

    return { formData, fieldRows, handleSubmit,sendData };
  }
});
</script>


<style scoped>
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {font-family: 'Nunito', sans-serif; color: #384047;}
.temp-row{display: flex; gap: 5px;}
form {width:100%}

h1 {margin: 0 0 30px 0;text-align: center;}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"], :deep(.input),
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  background: rgba(255,255,255,0.1);
  border: none;
  font-size: 16px;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 15px;
  width: 100%;
  background-color: #e8eeef;
  color: #8a97a0;
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin: 5px 0;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 0 4px 8px 0;
}

select {
  padding: 15px;
  border-radius: 2px;
}

.submit-btn{display:flex; justify-content: center; width:100%;padding: 0 15px;}
button {width:100%; font-weight: 500;
  background: var(--primary-color);
  color: var(--white-color);
  box-shadow: none;
  border: 0;
  margin: 20px 40px;
  padding: 19px 39px 18px 39px;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
}

fieldset {
  margin-bottom: 10px;
  border: none;
  padding: 0px 15px;
}

legend {margin-bottom: 10px; display: flex; align-items: center;}
legend .desc{margin-left:5px}
legend .ltitle{font-size: 1.4em; margin: 0; line-height: 1em; font-weight: bold;}
legend .dtxt{font-size: 13px; margin:0}

label {display: block; font-size: 14px; font-weight: bold;}

label.light {
  font-weight: 300;
  display: inline;
}

.number {
  background-color: var(--primary-color);
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255,255,255,0.2);
  border-radius: 100%;
}
.errors{padding: 0px 15px;}
.errors p{background:red; color:white; margin:5px; padding:5px 10px;
  border-radius: 10px; font-weight: bold; font-size: 14px;}
.success p{background: green;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;}
</style>
  