<template>
  <div class="main-content">
    <PageHeader :page="page" @action="createNewDept"/>
    <div class="content">
      <DataTable :tableData="tableData" @edit="editDept" @delete="DeleteDept"/>
    </div>
  </div>

  <ModalView
    :isVisible="state.addDeptModal"
    :fixclose="true"
    @close="state.addDeptModal = false">
    
    <DynamicForm ref="createDeptForm" :columns="1"
      :fields="newFields" @submit="addDepartment"
      :submitting="state.submitting" :errors="state.errors"
      :success="state.success" />
  </ModalView> 

  <ModalView
    :isVisible="state.editDeptModal"
    :fixclose="true"
    @close="state.editDeptModal = false">
    
    <DynamicForm :columns="1"
      :fields="editFields" @submit="editDepartment"
      :submitting="state.submitting" :errors="state.errors"
      :success="state.success" />
  </ModalView>
</template>

<script>
import icons from '@/assets/js/icons.js';
import PageHeader from '@/components/PageHeader.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import { defineComponent, ref, reactive, onMounted} from 'vue';
import ModalView from '@/components/ModalView.vue';
import DataTable from '@/components/DataTable.vue';
import call from "../composables/call.js";
import { urls } from "../assets/js/urls";
import { usePermissions } from '@/composables/usePermissions';

export default defineComponent({
  name: 'DepartmentsView',
  components:{PageHeader,DynamicForm,ModalView,DataTable},
  setup(){
    const state=reactive({addDeptModal:false, submitting:false, error:[],success:false,
      editDeptModal:false
    })
    const { hasPermission } = usePermissions();
    var tableOptions=[];
    var tableAction=[]
    var tableActionLabel="";

    const svgIcons = ref(icons);
    const page=reactive({title:"Departments", btn:"Create new",
    desc:"This page provides an overview of each team, their roles, and main contacts within the organization."+ 
          "Use this page to explore how each department functions and find the right contacts to assist with "+
          "your needs. Click on any department to learn more about its role and team members"})
    if(!hasPermission('Department', 'create')){
      page.btn="";
    }
    const newFields=reactive([
      { name: "Department Info", number: "1",data: 
        [{ type: 'text', name: 'name', label: 'Department Name', placeholder: 'Department Name', 
          value: ""  },
          { type: 'textarea', name: 'description', label: 'Description', placeholder: 'Description', 
          value: ""  }
        ]
      }
    ])
    const editFields=reactive([])

    function setErrors(res){
      var errors=[];
      Object.keys(res.msg).forEach(key => {
        var e=res.msg[key],er="";
        Object.keys(e).forEach(k => {
          er+=er=="" ? e[k] : ", "+e[k]
        })
        errors.push(er);
      });
      state.errors=errors;
    }

    function addDepartment(data){
      state.submitting=true;
      state.errors=[];
      data.addDepartment=(newFields.length + 1).toString();
      call({
        url:urls.role,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
            setTable(res.msg);
          }else{
            setErrors(res)
          }
        }
      })
    }
    
    if(hasPermission('Department', 'edit')){
      tableOptions.push("edit");
      tableAction.push(icons.edit)
      tableActionLabel="Actions";
    }
    
    if(hasPermission('Department', 'delete')){
      tableOptions.push("delete");
      tableAction.push(icons.trash)
      tableActionLabel="Actions";
    }

    const tableData = ref({
      rows: [],
      loading: true,
      columns: [
        { label: "SN" },
        { label: "Name" },
        { label: "Description" }
      ],
      options:{
        label:tableActionLabel,
        action_label:tableAction,
        action:tableOptions
      },
      total:0
    });

    function setTable(msg){
      var data={}
      tableData.value.loading = false;
      tableData.value.rows.length=0
      Object.keys(msg).forEach(set => {
        data={id:msg[set].id};
        var v=[];
        Object.keys(msg[set].val).forEach(row => {
          v.push(msg[set].val[row])
        });
        data.val=v;
        tableData.value.rows.push(data)
      });
      tableData.value.total=tableData.value.rows.length
    }

    onMounted(() => {
      call({
        url:urls.role,
        data:{getDepartment:""},
        success:(res)=>{
          state.loaded=true
          if(res.status){
            setTable(res.msg)
          }else{
            alert(res.msg);
          }
        }
      })
    });

    function createNewDept(){
      state.success=false;
      state.error=[]
      state.addDeptModal=true;
    }

    function editDept(i){
      state.success=false;
      state.error=[]
      var data=tableData.value.rows[i]
      editFields.length=0;
      editFields.push(
      { name: "Edit Department", number: "1",data: 
        [{ type: 'text', name: 'name', label: 'Department Name', placeholder: 'Department Name', 
          value: data.val[1]  },
          { type: 'textarea', name: 'description', label: 'Description', placeholder: 'Description', 
          value: data.val[2]  },
          { type: 'hidden', value: data.id, name:"id"}
        ]
      })
      state.editDeptModal=true;
    }

    function DeleteDept(i){
      var data=tableData.value.rows[i]
      tableData.value.loading=true;
      call({
        url:urls.role,
        data:{DeleteDept:data.id},
        success:(res)=>{
          if(res.status){
            setTable(res.msg);
          }else{
            alert(res.msg)
          }
        }
      })
    }

    function editDepartment(data){
      state.submitting=true;
      state.errors=[];
      data.editDepartment="";
      call({
        url:urls.role,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
            setTable(res.msg);
          }else{
            setErrors(res)
          }
        }
      })
    }

    return{icons: svgIcons.value, page,createNewDept,state,newFields,DeleteDept,
      addDepartment,tableData,editDept,editFields,editDepartment,hasPermission}
  }
})
</script>

<style scoped>
.card-in{background: var(--white-color); padding: 20px;}
.hd-dsc{font-size: 12px; padding: 0;}
.hd-ctr{display: flex; align-items: center;}
.hd-ctr h3{margin: 0;}
.hd-ctr .ic{width: 17px; height: 17px; fill: var(--primary-color); margin-left: 10px;
  line-height: 0; cursor:pointer;}

</style>
