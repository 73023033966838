<template>
    <div class="calendar-view">
        <input
            type="text"
            placeholder="Select a date"
            readonly
            :class="iclass"
            :value="formattedDate"
            @focus="showCalendar = true"
        />
        <div v-if="showCalendar" class="calendar-popup">
            <div class="calendar-header">
                <!-- If in month-year picker mode, show the month-year picker -->
                <span @click="toggleMonthYearPickerMode">
                    <span v-if="isMonthYearPickerMode">{{ currentMonthName }} {{ currentYear }}</span>
                    <span v-else>{{ months[currentMonth] }} {{ currentYear }}</span>
                </span>
                <span @click="prevMonth" v-if="!isMonthYearPickerMode">«</span>
                <span @click="nextMonth" v-if="!isMonthYearPickerMode">»</span>
            </div>

            <!-- Month and Year Picker -->
            <div v-if="isMonthYearPickerMode" class="month-year-picker">
                <select v-model="selectedMonth" @change="updateMonth">
                    <option v-for="(month, index) in months" :key="index" :value="index">{{ month }}</option>
                </select>
                <select v-model="selectedYear" @change="updateYear">
                    <option v-for="year in yearRange" :key="year" :value="year">{{ year }}</option>
                </select>
            </div>

            <!-- Calendar Days -->
            <div class="calendar-days">
                <span v-for="day in daysOfWeek" :key="day">{{ day }}</span>
            </div>
            <div class="calendar-dates">
                <template v-for="(date, index) in daysInMonth" :key="index">
                    <span
                        v-if="date"
                        :class="['calendar-date', { selected: isSelected(date), today: isToday(date) }]"
                        @click="selectDate(date)"
                    >
                        {{ date.getDate() }}
                    </span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
    name: "CalendarView",
    props: {
        modelValue: String, // Now expecting a formatted date string
        iclass: String
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const showCalendar = ref(false);
        const today = new Date();
        const currentYear = ref(today.getFullYear());
        const currentMonth = ref(today.getMonth());
        const selectedDate = ref(props.modelValue ? new Date(props.modelValue) : null);

        // Reactive reference for month/year picker mode
        const isMonthYearPickerMode = ref(false);

        // Reactive references for selected month and year
        const selectedMonth = ref(currentMonth.value);
        const selectedYear = ref(currentYear.value);

        const formattedDate = computed(() =>
            selectedDate.value ? selectedDate.value.toLocaleDateString('en-CA') : ""
        );

        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const yearRange = computed(() => {
            let range = [];
            for (let i = 1900; i <= today.getFullYear() + 10; i++) {
                range.push(i);
            }
            return range;
        });

        const daysInMonth = computed(() => {
            const days = [];
            const firstDayOfMonth = new Date(currentYear.value, currentMonth.value, 1);
            const lastDayOfMonth = new Date(currentYear.value, currentMonth.value + 1, 0);

            for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
                days.push(null);
            }

            for (let d = 1; d <= lastDayOfMonth.getDate(); d++) {
                days.push(new Date(currentYear.value, currentMonth.value, d));
            }

            return days;
        });

        const isToday = (date) => {
            const today = new Date();
            return date && date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
        };

        const isSelected = (date) => {
            return selectedDate.value && date && date.getDate() === selectedDate.value.getDate() && date.getMonth() === selectedDate.value.getMonth() && date.getFullYear() === selectedDate.value.getFullYear();
        };

        const selectDate = (date) => {
            selectedDate.value = date;
            showCalendar.value = false;
            emit("update:modelValue", formattedDate.value); // Emit as a formatted string
        };

        const prevMonth = () => {
            currentMonth.value = currentMonth.value === 0 ? 11 : currentMonth.value - 1;
            currentYear.value = currentMonth.value === 11 ? currentYear.value - 1 : currentYear.value;
        };

        const nextMonth = () => {
            currentMonth.value = currentMonth.value === 11 ? 0 : currentMonth.value + 1;
            currentYear.value = currentMonth.value === 0 ? currentYear.value + 1 : currentYear.value;
        };

        const currentMonthName = computed(() => months[currentMonth.value]);

        const toggleMonthYearPickerMode = () => {
            isMonthYearPickerMode.value = !isMonthYearPickerMode.value;
        };

        const updateMonth = () => {
            currentMonth.value = selectedMonth.value;
            isMonthYearPickerMode.value = false;  // Go back to calendar view
        };

        const updateYear = () => {
            currentYear.value = selectedYear.value;
            isMonthYearPickerMode.value = false;  // Go back to calendar view
        };

        return {
            showCalendar,
            currentYear,
            currentMonth,
            daysOfWeek,
            months,
            daysInMonth,
            isToday,
            isSelected,
            selectDate,
            prevMonth,
            nextMonth,
            formattedDate,
            currentMonthName,
            toggleMonthYearPickerMode,
            yearRange,
            selectedMonth,
            selectedYear,
            updateMonth,
            updateYear,
            isMonthYearPickerMode  // Make sure this is included in the return
        };
    }
};

</script>

<style scoped>
    .calendar-view { position: relative; }
    .calendar-view input { margin-bottom: 0 !important; }

    .calendar-popup {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10;
        width: 250px;
        padding: 10px;
    }

    .calendar-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .calendar-days, .calendar-dates {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;
        gap: 5px;
    }

    .calendar-date {
        padding: 5px;
        cursor: pointer;
    }

    .calendar-date.selected {
        background-color: var(--primary-color);
        color: white;
    }

    .calendar-date.today {
        font-weight: bold;
        color: var(--primary-color);
    }

    .month-year-picker select {
        margin-right: 5px;
    }
</style>
