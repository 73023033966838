<template>
  <div class="main-content">
    <PageHeader :page="page" @action="createNew"/>
    <div class="content">
      <DataTable :tableData="tableData"/>
    </div>
  </div>

  <ModalView
    :isVisible="state.addStaffModal"
    :fixclose="true"
    @close="state.addStaffModal = false">
    
    <DynamicForm ref="deptForm" :columns="2"
      :fields="newFields" @submit="addUser"
      :submitting="state.submitting" :errors="state.errors"
      :success="state.success" />
  </ModalView> 
</template>

<script>
import icons from '@/assets/js/icons.js';
import PageHeader from '@/components/PageHeader.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import { defineComponent, ref, reactive, onMounted} from 'vue';
import ModalView from '@/components/ModalView.vue';
import DataTable from '@/components/DataTable.vue';
import call from "../composables/call.js";
import { urls } from "../assets/js/urls";
import { usePermissions } from '@/composables/usePermissions';

export default defineComponent({
  name: 'StaffMgt',
  components:{PageHeader,DynamicForm,ModalView,DataTable},
  setup(){
    const { hasPermission } = usePermissions();
    const state=reactive({addStaffModal:false, submitting:false, error:[],success:false,
      editDeptModal:false, etype:[]
    })
    const svgIcons = ref(icons);
    const deptForm=ref(null)
    const etype=ref([])
    const depts=ref([])
    const supe=ref([])
    const jobs=ref([])
    const page=reactive({title:"Staff Management", btn:"Create new",
    desc:"The Staff Management page provides tools to oversee and organize your team effectively."+
    " Use this page to manage staff roles, assign tasks, and update contact information. "+
    "Click on any staff member to view details, adjust permissions, or track performance metrics"})
    if(!hasPermission('Team', 'create')){
      page.btn="";
    }
    
    const newFields=ref([
      { name: "Personal Info", number: "1",data: 
        [{ type: 'text', name: 'name', label: 'Staff Name',
          value: ""  },
          { type: 'select', name: 'gender', label: 'Gender',
            value: "",options:[{label:"Male", value:"male"}, {label:"Female", value:"female"},]
          },
          { type: 'text', name: 'phone', label: 'Phone No', 
          value: ""  }
        ]
      },
      { name: "Login Info", number: "2",data: 
        [{ type: 'text', name: 'email', label: 'Work Email', 
          value: ""  },
          { type: 'password', name: 'password', label: 'Password', 
          value: ""  }
        ]
      },
      { name: "Office Info", number: "3",data: 
        [{ type: 'select', name: 'title', label: 'Job Title',
          value: "",options:jobs  },
          { type: 'select', name: 'dept', label: 'Department', 
          value: "", options:depts },
          { type: 'text', name: 'e_id', label: 'Employee ID', 
          value: ""  },
          { type: 'select', name: 'etype', label: 'Employment Type', 
          value: "", options:etype},
          { type: 'select', name: 'supervisor', label: 'Supervisor',
          value: "",options:supe  },
          { type: 'date', name: 'date', label: 'Employment Date', 
          value: ""  },
          { type: 'text', name: 'salary', label: 'Salary', 
          value: ""  }
        ]
      }
    ])
    function setErrors(res){
      var errors=[];
      Object.keys(res.msg).forEach(key => {
        var e=res.msg[key],er="";
        Object.keys(e).forEach(k => {
          er+=er=="" ? e[k] : ", "+e[k]
        })
        errors.push(er);
      });
      state.errors=errors;
    }

    function addUser(data){
      state.submitting=true;
      state.errors=[];
      data.addUser="";
      call({
        url:urls.role,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
            // setTable(res.msg);
          }else{
            setErrors(res)
          }
        }
      })
    }

    const tableData = ref({
      rows: [],
      loading: true,
      columns: [
        { label: "SN" },
        { label: "Name" },
        { label: "Description" }
      ],
      options:{
        label:"Actions",
        action_label:[icons.edit, icons.trash],
        action:["edit","delete"]
      },
      total:0
    });

    function setTable(msg){
      var data={}
      tableData.value.loading = false;
      tableData.value.rows.length=0
      Object.keys(msg).forEach(set => {
        data={id:msg[set].id};
        var v=[];
        Object.keys(msg[set].val).forEach(row => {
          v.push(msg[set].val[row])
        });
        data.val=v;
        tableData.value.rows.push(data)
      });
      tableData.value.total=tableData.value.rows.length
    }

    onMounted(() => {
      call({
        url:urls.role,
        data:{startStaff:""},
        success:(res)=>{
          state.loaded=true
          if(res.status){
            var d=res.msg;
            etype.value=[];
            depts.value=[];
            supe.value=[];
            jobs.value=[];
            Object.keys(d.etype).forEach(k => {
              etype.value.push(d.etype[k])
            })
            Object.keys(d.job).forEach(k => {
              jobs.value.push(d.job[k])
            })
            Object.keys(d.dept).forEach(k => {
              depts.value.push(d.dept[k])
            })
            Object.keys(d.users).forEach(k => {
              supe.value.push(d.users[k])
            })
            setTable(d.depts)
          }else{
            alert(res.msg);
          }
        }
      })
    });

    function createNew(){
      state.success=false;
      state.error=[]
      state.addStaffModal=true;
    }

    return{icons: svgIcons.value, page,createNew,state,newFields,deptForm,
      addUser,tableData,hasPermission}
  }
})
</script>

<style scoped>
.card-in{background: var(--white-color); padding: 20px;}
.hd-dsc{font-size: 12px; padding: 0;}
.hd-ctr{display: flex; align-items: center;}
.hd-ctr h3{margin: 0;}
.hd-ctr .ic{width: 17px; height: 17px; fill: var(--primary-color); margin-left: 10px;
  line-height: 0; cursor:pointer;}

</style>
