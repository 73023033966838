// import {useStore} from "vuex";
export default function call({url,data,success=() => {},error=() =>{},aborter}){
    // const store=useStore();
    let req=null;
    if(aborter && aborter instanceof AbortController){
        req = new Request(url,{method: 'POST',headers: {'Content-Type': 'application/json'},credentials:"include", body: JSON.stringify(data),signal: aborter.signal});
    }else{
        req = new Request(url,{method: 'POST',headers: {'Content-Type': 'application/json'},credentials:"include", body: JSON.stringify(data)});
    }
    fetch(req).then(res => {
        if (!res.ok) {
            console.log("error")
            throw Error(res);
        }
        return res.json();
    }).then(res => {
        // var login;
        if(!res.session){
            // login={
            //     status:false,
            //     auth_code:res.auth_code
            // }
            // store.dispatch("Login/setLogin",login);
            window.location="http://localhost/officeops";
        }else if(res.redirect){
            // login={
            //     status:true,
            //     auth_code:res.auth_code
            // }
            // store.dispatch("Login/setLogin",login);
            window.location=res.redirect;
            return;
        }else{
            var result=res.data;
            // login={
            //     status:true,
            //     auth_code:res.auth_code
            // }
            // store.dispatch("Login/setLogin",login);
            success(result);
        }
    }).catch(()=> {
        error("Network error, please ensure you have internet connection");
    });
}