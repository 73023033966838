<template>
  <div class="wrapper" v-if="state.login">
    <DashboardView :menu="state.menuState" @toggleMenu="toggleMenu()"/>
    <TopNavigation @toggleMenu="toggleMenu()" :menu="state.menuState"/>
    <div v-if="state.login" class="main-content" :class="{'open' : state.menuState}">
      <router-view />
    </div>
  </div>
  <div v-if="!state.login">Loading</div>
</template>

<script>
import DashboardView from './components/DashboardView.vue';
import { computed, reactive } from "vue";
import { useDarkMode } from "./composables/useDarkMode.js";
import TopNavigation from './components/TopNavigation.vue';
import {useStore} from "vuex";
import { urls } from "@/assets/js/urls";
export default {
  name: 'App',
  components:{
    DashboardView,TopNavigation
  },
  setup() {
    const store=useStore();
    const user=computed(()=>store.state.User.user);
    const state=reactive({darkMode:"false",login:false, menuState:true})
    const { darkMode, applyDarkMode } = useDarkMode(state.darkMode);

    function transformPermissions(apiResponse) {
      return Object.entries(apiResponse).flatMap(([resource, actions]) =>
        Object.keys(actions)  // Use Object.keys to iterate over the actions
          .filter(action => actions[action])  // Only keep actions where allowed is true
          .map(action => ({ name: resource, type: action }))  // Map the results into the desired format
      );
    }

    function verifySess(){
      const update = {verifySess: "verifySess"};
      const options = {method: 'POST',headers: {'Content-Type': 'application/json'},credentials:"include", body: JSON.stringify(update)};
      fetch(urls.dashboard, options).then(data => {
          if (!data.ok) {
              throw Error(data);
          }
          return data.json();
      }).then(res => {
        if(!res.session){
          window.location=urls.base;
        }else if(res.redirect){
          window.location=res.redirect;
          return;
        }else{
          var r=res.data.msg
          var user={
            name:r.name,
            dp:r.dp,
            persona:r.persona,
            links:r.links,
            username:r.usrid
          }
          var login={
              status:true,
              auth_code:r.auth_code
          }
          store.dispatch("Login/setLogin",login);
          store.dispatch("User/setUser",user);
          store.dispatch('Permission/setPermissions', transformPermissions(r.permissions));
          state.login=true;
        }
      }).catch(()=> {
        window.location=urls.base;
      });
    }

    function toggleMenu(){
      state.menuState=!state.menuState
    }

    return { darkMode,state,applyDarkMode, user, verifySess,toggleMenu };
  },
  beforeCreate: function(){
    this.verifySess();
  }
}
</script>
<style>
@import url("./assets/css/fonts.css");
@import url("./assets/css/vixcss.css");
@import url("./assets/css/main.css");

</style>