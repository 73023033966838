<template>
    <div>
        <PageHeader :page="page" :template="2" @action="createNew" />
        <div class="content">
            <DataTable :tableData="tableData" @tasks="showTask"/>
        </div>
    </div>

    <ModalView
        :isVisible="state.CreateModal"
        :fixclose="true"
        @close="state.CreateModal = false">
        
        <DynamicForm ref="createForm" :columns="2"
        :fields="newFields" @submit="createProject"
        :submitting="state.submitting" :errors="state.errors"
        :success="state.success" />
    </ModalView> 
</template>
<script>
import icons from '@/assets/js/icons.js';
import { defineComponent, reactive, ref, onMounted} from 'vue';
import PageHeader from '@/components/PageHeader.vue';
import DataTable from '@/components/DataTable.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import ModalView from '@/components/ModalView.vue';
import call from "../composables/call.js";
import { urls } from "../assets/js/urls";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'DepartmentsView',
  components:{PageHeader, DataTable,DynamicForm,ModalView},
  setup(){
    const createForm=ref(null);
    const router = useRouter();
    const state=reactive({CreateModal:false, submitting:false,error:[],success:false,
    });
    const svgIcons = ref(icons);
    const depts=ref([])
    const page=reactive({title:"Projects", btn:"Create Project",
    desc:"All your project task you're involved in"})
    const tableData = ref({
      rows: [],
      loading: true,
      columns: [
        { label: "Project name" },
        { label: "Involved" },
        { label: "Due date" },
        { label: "Status" },
      ],
      options:{
        label:"Actions",
        action_label:[icons.edit,icons.skills, icons.trash],
        action:["edit","tasks","delete"]
      },
      total:0
    });
    const newFields=ref([
      { name: "Create Project", number: "1",data: 
        [{ type: 'text', name: 'name', label: 'Project Name',
          value: ""  },
          { type: 'date', name: 'deadline', label: 'Deadline',
          value: ""  },
          { type: 'selectGroup', name: 'privacy', label: 'Privacy',
            value: "",
            options:[
                {label:"Generic",options:[
                    {label:"Company wide", value:"1"}, 
                    {label:"Members only", value:"2"},
                    {label:"Private to me", value:"3"},
                ]},
                {label:"Departments",options:depts},
            ]
          }
        ]
      }
    ])
    function createNew(){
      state.success=false;
      state.error=[]
      state.CreateModal=true;
    }

    function setTable(msg){
      var data={}
      tableData.value.loading = false;
      tableData.value.rows.length=0
      Object.keys(msg).forEach(set => {
        data={id:msg[set].id};
        var v=[];
        Object.keys(msg[set].val).forEach(row => {
          v.push(msg[set].val[row])
        });
        data.val=v;
        tableData.value.rows.push(data)
      });
      tableData.value.total=tableData.value.rows.length
    }

    onMounted(() => {
      call({
        url:urls.task,
        data:{startProject:""},
        success:(res)=>{
          state.loaded=true
          if(res.status){
            var d=res.msg;
            depts.value=[];
            Object.keys(d.dept).forEach(k => {
              depts.value.push(d.dept[k])
            })
            setTable(d.projects);
          }else{
            alert(res.msg);
          }
        }
      })
    });

    function createProject(data){
        state.submitting=true;
        state.errors=[];
        data.createProject="";
        call({
            url:urls.task,
            data:data,
            success:(res)=>{
                state.submitting=false;
                if(res.status){
                state.success=true;
                // setTable(res.msg);
                }else{
                setErrors(res)
                }
            }
        })
    }

    function setErrors(res){
        var errors=[];
        Object.keys(res.msg).forEach(key => {
        var e=res.msg[key],er="";
        Object.keys(e).forEach(k => {
            er+=er=="" ? e[k] : ", "+e[k]
        })
        errors.push(er);
        });
        state.errors=errors;
    }

    function showTask(i){
        var data=tableData.value.rows[i];
        router.push({ name: 'tasksview', params: { project: data.id } });
    }


    return{page,icons: svgIcons.value,tableData,state,createForm,
        newFields,createNew,createProject,showTask
    }
  }
})

</script>   

<style scoped>
p{color:#737373 !important; font-family: Inter, Helvetica, Arial, sans-serif;}
.box > *{padding: 0 calc(1.5rem * 0.5);}
.box .card{background:var(--white-color); border-radius: 0.5rem}
.card-header:first-child {border-radius: 0.5rem 0.5rem 0 0;padding: 0.5rem !important;
    padding-left: 1rem !important;background-color: var(--white-color);
    border-bottom: 0 solid #e5e5e5;}
.iconwrap{border-radius: 0.5rem; width: 48px;height: 48px; background-position: center;
    background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
    text-align: center !important; display: flex; align-items: center; justify-content: center;}
.iconwrap .ic{width:18px; height:18px; fill:var(--white-color);line-height: 0em;}
.text-sm {font-size: 0.875rem !important; line-height: 1.5;}
.text-capitalize {text-transform: capitalize !important;}
h4{font-size: 1.5rem; line-height: 1.375; font-weight: 600;}
hr.horizontal.dark {background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);}
.my-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
hr.horizontal {background-color: transparent;}
hr {border-top: none; height: 1px;}
hr {color: inherit; border: 0; opacity: 0.25;}
.ps-3 {padding-left: 1rem !important;}
.p-2 {padding: 0.5rem !important;}
</style>
<style>
.iclst .trash_ic {stroke: var(--red); fill:none}
</style>