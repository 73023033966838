import { computed } from 'vue';
import { useStore } from 'vuex';

export function usePermissions() {
  const store = useStore();

  const hasPermission = (resource, action) => {
    return store.getters['Permission/hasPermission'](resource, action);
  };

  const permissions = computed(() => store.state.Permission.permissions);

  return { hasPermission, permissions };
}
