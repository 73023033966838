<template>
    <div>
        <PageHeader :page="page" :template="2" @action="createNew" v-if="!state.loading"/>
        <div class="content" v-if="!state.loading">
            <DataTable :tableData="tableData" @mark="mark" :rowClass="getRowClass"/>
        </div>
        <div class="temp-row" v-if="state.loading" style="text-align: center;">
          <img src="@/assets/img/ajldr.gif" style="width:100px"/>
        </div>
    </div>

    <ModalView
        :isVisible="state.CreateModal"
        :fixclose="true"
        @close="state.CreateModal = false">
        
        <DynamicForm ref="createForm" :columns="2"
        :fields="newFields" @submit="createTask" :addMore="'Add Assignee'"
        :submitting="state.submitting" :errors="state.errors" @sendData="addAssignee"
        :success="state.success" />
    </ModalView> 
</template>
<script>
import icons from '@/assets/js/icons.js';
import { defineComponent, reactive, ref, onMounted, computed} from 'vue';
import PageHeader from '@/components/PageHeader.vue';
import DataTable from '@/components/DataTable.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import ModalView from '@/components/ModalView.vue';
import call from "../composables/call.js";
import { urls } from "../assets/js/urls";
import { useRoute } from 'vue-router';

export default defineComponent({
  components:{PageHeader, DataTable,DynamicForm,ModalView},
  setup(){
    const createForm=ref(null);
    const state=reactive({CreateModal:false, submitting:false,error:[],success:false,
      loading:true
    });
    const svgIcons = ref(icons);
    const route = useRoute();
    const project = computed(() => route.params.project)
    const staff=ref([])
    const page=ref({title:"Projects", btn:"Create task",
    desc:"All tasks in this project"})
    const tableData = ref({
      rows: [],
      loading: true,
      columns: [
        { label: "Task name" },
        { label: "Assignee" },
        { label: "Due date" },
        { label: "Status" },
      ],
      options:{
        label:"",
        action_label:[icons.edit,icons.skills, icons.trash],
        action:["edit","tasks","delete"],
        rowAction:{row1:{icon:icons.check,action:"mark"}}
      },
      total:0
    });
    
    const newFields=ref([
      { name: "Create task", number: "1",data: 
        [{ type: 'text', name: 'name', label: 'Task Name',
          value: ""  },
          { type: 'date', name: 'deadline', label: 'Deadline',
          value: ""  },
          { type: 'hidden', name: 'project', value: project.value},
        ]
      },
      { name: "Assign task", number: "2",data: 
        [{ type: 'select', name: 'assignee_1', label: 'Staff',
          options:staff}
        ]
      }
    ])
    function createNew(){
      state.success=false;
      state.error=[]
      state.CreateModal=true;
    }

    function setTable(msg){
      var data={}
      tableData.value.loading = false;
      tableData.value.rows.length=0
      Object.keys(msg).forEach(set => {
        data={id:msg[set].id,status:msg[set].status};
        var v=[];
        Object.keys(msg[set].val).forEach(row => {
          v.push(msg[set].val[row])
        });
        data.val=v;
        tableData.value.rows.push(data)
      });
      tableData.value.total=tableData.value.rows.length
    }

    onMounted(() => {
      call({
        url:urls.task,
        data:{startTask:project.value},
        success:(res)=>{
          state.loading=false
          if(res.status){
            var d=res.msg;
            staff.value=[];
            Object.keys(d.staff).forEach(k => {
              staff.value.push(d.staff[k])
            })
            page.value.title=d.title;
            setTable(d.tasks);
          }else{
            alert(res.msg);
          }
        }
      })
    });

    function createTask(data){
      state.submitting=true;
      state.errors=[];
      data.createTask=(newFields.value.length-1).toString();
      call({
        url:urls.task,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
          }else{
            setErrors(res)
          }
        }
      })
    }

    function setErrors(res){
        var errors=[];
        Object.keys(res.msg).forEach(key => {
        var e=res.msg[key],er="";
        Object.keys(e).forEach(k => {
            er+=er=="" ? e[k] : ", "+e[k]
        })
        errors.push(er);
        });
        state.errors=errors;
    }

    function addAssignee(f){
      for(var i=0; i<newFields.value.length; i++){
        newFields.value[i].data.forEach(field => {
        if (f[field.name]) {
            field.value = f[field.name];
          }
        });
      }
      newFields.value.push(
        { name: "Assign task", number:(newFields.value.length+1).toString(),
      data: 
        [{ type: 'select', name: 'assignee_'+(newFields.value.length).toString(), label: 'Institution', placeholder: 'Institution', 
          options:staff }
        ]
      })
    }

    function mark(i){
        var data=tableData.value.rows[i],
        oldData=data.status;
        if(oldData != "1" && oldData != "0" && oldData != "2"){
          alert("Only assignees can mark or unmark a task");
          return;
        }
        tableData.value.rows[i].status="-1"
        call({
          url:urls.task,
          data:{markTask:data.id},
          success:(res)=>{
            state.submitting=false;
            if(res.status){
              setTable(res.msg)
            }else{
              tableData.value.rows[i].status=oldData
              setErrors(res)
            }
          }
        })
    }

    function getRowClass(row) {
      console.log("class: ",row)
      if(row.status=="-1"){return "marking"}
      if(row.status=="0" || row.status=="3"){return "completed"}
      if(row.status=="2" || row.status=="4"){return "overdue"}
      return "";
    }


    return{page,icons: svgIcons.value,tableData,state,createForm,mark,
        newFields,createNew,createTask,addAssignee,getRowClass
    }
  }
})

</script>   

<style scoped>
p{color:#737373 !important; font-family: Inter, Helvetica, Arial, sans-serif;}
.box > *{padding: 0 calc(1.5rem * 0.5);}
.box .card{background:var(--white-color); border-radius: 0.5rem}
.card-header:first-child {border-radius: 0.5rem 0.5rem 0 0;padding: 0.5rem !important;
    padding-left: 1rem !important;background-color: var(--white-color);
    border-bottom: 0 solid #e5e5e5;}
.iconwrap{border-radius: 0.5rem; width: 48px;height: 48px; background-position: center;
    background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
    text-align: center !important; display: flex; align-items: center; justify-content: center;}
.iconwrap .ic{width:18px; height:18px; fill:var(--white-color);line-height: 0em;}
.text-sm {font-size: 0.875rem !important; line-height: 1.5;}
.text-capitalize {text-transform: capitalize !important;}
h4{font-size: 1.5rem; line-height: 1.375; font-weight: 600;}
hr.horizontal.dark {background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);}
.my-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
hr.horizontal {background-color: transparent;}
hr {border-top: none; height: 1px;}
hr {color: inherit; border: 0; opacity: 0.25;}
.ps-3 {padding-left: 1rem !important;}
.p-2 {padding: 0.5rem !important;}
:deep(.completed .tdata .ic){fill:var(--primary-color) !important}
:deep(.marking .tdata .ic){display:none !important}
:deep(.overdue td:nth-child(3)){color:red !important; font-weight: bold}
</style>