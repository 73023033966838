<template>
    <div v-if="tableData.loading" class="loading-indicator">
        <img src="@/assets/img/ajldr.gif" style="width:100px"/>
    </div>
    <div class="datatable" v-else-if="!tableData.loading && paginatedData.length > 0">
      <!-- Controls: Row Count and Search -->
      <div class="datatable-controls">
        <div class="row-count">
          <select id="rowsPerPage" v-model.number="rowsPerPage" class="datatable-dropdown">
            <option :value="option" v-for="option in rowsPerPageOptions" :key="option">
              {{ option }}
            </option>
          </select>
          <label for="rowsPerPage">Entries per page:</label>
        </div>
        <div class="search-bar">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Search..."
            class="datatable-search"
          />
        </div>
      </div>
  
      <!-- Responsive Table -->
      <div class="table-wrapper">
        <table>
          <thead>
            <tr class="head">
              <th
                v-for="col in tableData.columns"
                :key="col.label"
                @click="sortTable(col.label)"
                class="sortable"
              >
                {{ col.label }}
                <span v-if="sortedColumn === col.label">
                  {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                </span>
              </th>
              <th v-if="tableData.options.label != ''">
                {{ tableData.options.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, ridx) in paginatedData" :key="row.id"
                :class="getClassForRow(row, ridx)">
              <td v-for="(c, index) in tableData.columns" :key="index">
                <div class="tdata">
                  <div class="ic" 
                      v-if="tableData.options.rowAction && tableData.options.rowAction[`row${index + 1}`]" 
                      v-html="tableData.options.rowAction[`row${index + 1}`].icon"
                      @click="rowIconClick(tableData.options.rowAction[`row${index + 1}`].action,ridx)">
                  </div>
                  <span>{{ row.val[index] }}</span>
                </div>
              </td>
              <td v-if="tableData.options.label != ''">
                <div class="iclst">
                  <div v-for="(ic,i) in tableData.options.action_label" :key="i" v-html="ic" @click="iconClick(i,ridx)"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Footer: Pagination and Summary -->
      <div class="datatable-footer">
        <div class="summary">
          Showing {{ startEntry }} to {{ endEntry }} of {{ totalEntries }} rows
        </div>
        <div class="pagination">
          <span @click="goToPreviousPage" :disabled="currentPage === 1">Prev</span>
          <span
            v-for="page in totalPages"
            :key="page"
            :class="{ active: page === currentPage }"
            @click="setPage(page)"
          >
            {{ page }}
          </span>
          <span @click="goToNextPage" :disabled="currentPage === totalPages">Next</span>
        </div>
      </div>
    </div>
    <div v-else class="no-data-indicator">No data available</div>
  </template>
  
  <script>
  import { ref, computed, watch } from "vue";
  
  export default {
    name: "DataTable",
    props: {
      tableData: {
        type: Object,
        required: true,
        default: () => ({
          rows: [],
          loading: false,
          columns: [],
          total: 0,
          options:{
            label:"Actions",
            action_label:[],
            action:[]
          }
        }),
      },
      rowClass: {
        type: Function,
        default: () => () => "", // Default to no class
      }
    },
    setup(props, { emit }) {
      const searchQuery = ref("");
      const sortedColumn = ref(null);
      const sortOrder = ref("asc");
      const currentPage = ref(1);
      const rowsPerPageOptions = ref([10, 30, 50, 100]);
      const rowsPerPage = ref(rowsPerPageOptions.value[0]);
  
      // Local data copy
      const localData = ref([...props.tableData.rows]);
      const totalEntries = ref(props.tableData.total);
  
      // Watch for changes in `tableData.rows` and update `localData`
      watch(
        () => props.tableData.rows,
        (newData) => {
          localData.value = [...newData];
        },
        { deep: true, immediate: true }
      );
  
      watch(
        () => props.tableData.total,
        (newTotal) => {
          totalEntries.value = newTotal;
        },
        { immediate: true }
      );
  
      const filteredData = computed(() => {
        if (!searchQuery.value) return localData.value;
        const query = searchQuery.value.toLowerCase();
        return localData.value.filter((row) =>
          Object.values(row).some((value) =>
            String(value).toLowerCase().includes(query)
          )
        );
      });
  
      const totalPages = computed(() =>
        Math.ceil(totalEntries.value / rowsPerPage.value)
      );
      const startEntry = computed(() =>
        totalEntries.value === 0 ? 0 : (currentPage.value - 1) * rowsPerPage.value + 1
      );
      const endEntry = computed(() =>
        Math.min(currentPage.value * rowsPerPage.value, totalEntries.value)
      );
  
      const paginatedData = computed(() => {
        const start = (currentPage.value - 1) * rowsPerPage.value;
        const end = start + rowsPerPage.value;
        return filteredData.value.slice(start, end);
      });
  
      const setPage = (page) => (currentPage.value = page);
      const goToNextPage = () => {
        if (currentPage.value < totalPages.value) currentPage.value++;
      };
      const goToPreviousPage = () => {
        if (currentPage.value > 1) currentPage.value--;
      };
  
      const sortTable = (column) => {
        if (sortedColumn.value === column) {
          sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
        } else {
          sortedColumn.value = column;
          sortOrder.value = "asc";
        }
        localData.value.sort((a, b) => {
          const compareA = a[column];
          const compareB = b[column];
          if (compareA < compareB) return sortOrder.value === "asc" ? -1 : 1;
          if (compareA > compareB) return sortOrder.value === "asc" ? 1 : -1;
          return 0;
        });
      };

      function iconClick(i,idx){emit(props.tableData.options.action[i],idx)}

      function rowIconClick(action,idx){emit(action,idx)}

      const getClassForRow=(row, columnIndex) => {
        // Use the provided function to compute the class
        return props.rowClass(row, columnIndex);
      };
  
      return {
        searchQuery,iconClick,rowIconClick,getClassForRow,
        currentPage,
        rowsPerPage,
        rowsPerPageOptions,
        totalPages,
        totalEntries,
        sortedColumn,
        sortOrder,
        startEntry,
        endEntry,
        paginatedData,
        setPage,
        goToNextPage,
        goToPreviousPage,
        sortTable,
      };
    },
  };
  </script>
  
<style scoped>
*{--light-row-color:#ebebeb; --white-color:#fff; --white-color-hover:rgb(172 172 172 / 10%);
--odd-td-first: #d9d9d9;--even-td-first: rgb(103 145 168 / 10%)}
.datatable {width: 100%; margin: 20px 0; background: var(--white-color); border-radius: 20px; padding: 20px;}

.datatable-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.row-count {font-size: 14px; display: flex; align-items: center;}

.search-bar {width: min(200px, 90%);}

.datatable-search {width: 100%; padding: 8px; font-size: 16px; box-sizing: border-box;}

.datatable-dropdown {padding: 4px; margin-right: 8px; width: auto; border: 1px solid #ddd;
    background: transparent;}

.table-wrapper {overflow-x: auto;}

/* Table styles */
table {width: 100%;border-collapse: collapse;min-width: 600px;}
th{border:0 !important}

th, td {padding: 10px; text-align: left;}
th.sortable {cursor: pointer;user-select: none;}
tr:nth-child(odd):not(.head){background: var(--light-row-color);}
tr:nth-child(odd):not(.head) td:nth-child(1){background:var(--odd-td-first)}
tr:nth-child(even):not(.head) td:nth-child(1){background:var(--even-td-first)}
tr:nth-child(even){background: var(--white-color);}
tr:nth-child(odd):not(.head):hover, tr:nth-child(odd):not(.head):hover td:nth-child(1){background: rgb(76 75 75 / 20%) !important;}
th.sortable:hover,tr:nth-child(even):hover,tr:nth-child(even):hover td:nth-child(1){background-color: var(--white-color-hover) !important;}
.iclst{display:flex; align-items: center; gap:5px}
.iclst > div{height: 15px; fill: var(--black); stroke:var(--black); cursor: pointer; display: flex; 
  align-items: center; justify-content: center; cursor:pointer}
.tdata{display:flex; align-content: center;gap: 5px}
.tdata span{display: flex; align-items: center;}
.tdata .ic{width:15px; height:15px; fill:var(--black); stroke: var(--black);
  line-height: 1.4em; cursor:pointer}
/* Pagination styles */
.datatable-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.pagination {
  display: flex;
  gap: 4px;
}

.pagination span { padding: 4px 8px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.2s;
}

.pagination span:hover {
  background-color: #f0f0f0;
}

.pagination span.active {
  background-color: #007bff;
  color: #fff;
}

.pagination span:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Responsive styles */
@media (max-width: 768px) {
  .datatable-controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    width: 100%;
    margin-top: 10px;
  }

  .datatable-search {
    font-size: 14px;
  }
  .datatable-footer{flex-wrap:wrap}
  table {
    font-size: 14px;
  }
}
.loading-indicator {text-align: center; background: var(--white-color); border-radius: 10px;}
.no-data-indicator {background: var(--white-color); text-align: center; padding: 20px;
  font-size: 16px;color: #555;}
</style>  