<template>
  <div class="main-content">
    <h1>NO PERMISSION</h1>
  </div>
</template>
<style scoped>
.main-content{background:var(--primary-color); height: 100vh;
  height: calc(100vh - 70px); display: flex; align-items: center; justify-content: center;}
.main-content h1{color:var(--white-color)}
</style>
