<template>
  <div class="main-content">
    <PageHeader :page="page" @action="createNewRole" />
    <div class="card">
      <div class="card-in">
        <div class="temp-row" v-if="!state.loaded" style="text-align: center;">
          <img src="@/assets/img/ajldr.gif" style="width:100px"/>
        </div>
        <div class="roles" v-if="state.loaded" >
          <div class="role">
            <div>Name</div>
            <div>Permissions</div>
            <div>Staff No</div>
            <div></div>
          </div>
          <div class="role" v-for="(role, key) in state.roles" :key="key">
            <div>
              {{role.name}}
              <div class="mview">
                <div v-html="icons.trash" class="ic" @click="deleteRole(key)"></div>
                <div v-html="icons.edit" class="ic" style="stroke:var(--primary-color)" @click="editRole(key)"></div>
              </div>
            </div>
            <div>{{role.perm}}</div>
            <div>{{role.staff}}</div>
            <div>
              <div v-html="icons.trash" class="ic" @click="deleteRole(key)" v-if="hasPermission('Roles', 'delete')"></div>
              <div v-html="icons.edit" class="ic" style="stroke:var(--primary-color)" @click="editRole(key)" v-if="hasPermission('Roles', 'edit')"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- create role modal -->
  <ModalView
    :isVisible="state.createRoleModal"
    :fixclose="true"
    @close="state.createRoleModal = false">
    
    <div class="temp-row">
        <div class="temp-input">
          <label for="name">Role (Job Title)</label>
          <input type="text" id="name" v-model="createData['role']">
      </div>
    </div>

    <label class="lbl">Permissions</label>
    <div class="temp-row perm" v-for="(f, key) in createFields" :key="key">
      <div class="temp-input">
        <select :name="f.name" v-model="createData[f.name]">
          <optgroup v-for="(group, idx) in state.proles" :key="idx" :label="group.label">
            <option v-for="(option, i) in group.options" :key="i" :value="option.value">
              {{ option.label }}
            </option>
          </optgroup>
        </select>
        <div class="chbx v-flex align-items-center">
          <div class="bx">
            <input disabled="true" type="checkbox" :name="f.name+'_r'" v-model="createData[f.name+'_r']"/>
            <label>Read</label>
          </div>
          <div class="bx">
            <input  type="checkbox" :name="f.name+'_c'" v-model="createData[f.name+'_c']"/>
            <label>Create</label>
          </div>
          <div class="bx">
            <input  type="checkbox" :name="f.name+'_e'" v-model="createData[f.name+'_e']"/>
            <label>Edit</label>
          </div>
          <div class="bx">
            <input  type="checkbox" :name="f.name+'_d'" v-model="createData[f.name+'_d']"/>
            <label>Delete</label>
          </div>
        </div>
      </div>
    </div>

    <div class="errors" v-if="!state.submitting">
      <p v-for="(e,i) in state.errors" :key="i">{{e}}</p>
    </div>

    <div class="temp-row success" v-if="state.success" style="justify-content: center">
      <p>Form upload successful</p>
    </div>

    <div v-if="!state.submitting && !state.success" class="temp-row v-flex align-items-center justify-content-between">
      <div class="btn-primary" @click="addCFields">Add more</div>
      <div class="btn-full" @click="createRole">Submit</div>
    </div>
    <div class="temp-row" v-if="state.submitting" style="text-align: center;">
      <img src="@/assets/img/ajldr.gif" style="width:100px"/>
    </div>

  </ModalView>
  <!-- end of create role modal -->
   
  <!-- edit role modal -->
  <ModalView
    :isVisible="state.editRoleModal"
    :fixclose="true"
    @close="state.editRoleModal = false">
    
    <div class="temp-row">
        <div class="temp-input">
          <label for="name">Role (Job Title)</label>
          <input type="text" id="name" v-model="editData['role']">
      </div>
    </div>

    <label class="lbl">Permissions</label>
    <div class="temp-row perm" v-for="(f, key) in editFields" :key="key">
      <div class="temp-input v-flex align-items-center">
        <div>
          <div v-html="icons.trash" class="ic" style="margin-right: 10px;"
          @click="deletePermission(key)"></div>
        </div>
        <div style="flex:1">
          <select :name="f.name" v-model="editData[f.name]">
            <optgroup v-for="(group, idx) in state.proles" :key="idx" :label="group.label">
              <option v-for="(option, i) in group.options" :key="i" :value="option.value">
                {{ option.label }}
              </option>
            </optgroup>
          </select>
          <div class="chbx v-flex align-items-center">
            <div class="bx">
              <input disabled="true" type="checkbox" :name="f.name+'_r'" v-model="editData[f.name+'_r']"/>
              <label>Read</label>
            </div>
            <div class="bx">
              <input  type="checkbox" :name="f.name+'_c'" v-model="editData[f.name+'_c']"/>
              <label>Create</label>
            </div>
            <div class="bx">
              <input  type="checkbox" :name="f.name+'_e'" v-model="editData[f.name+'_e']"/>
              <label>Edit</label>
            </div>
            <div class="bx">
              <input  type="checkbox" :name="f.name+'_d'" v-model="editData[f.name+'_d']"/>
              <label>Delete</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="errors" v-if="!state.submitting">
      <p v-for="(e,i) in state.errors" :key="i">{{e}}</p>
    </div>

    <div class="temp-row success" v-if="state.success" style="justify-content: center">
      <p>Form upload successful</p>
    </div>

    <div v-if="!state.submitting && !state.success" class="temp-row v-flex align-items-center justify-content-between">
      <div class="btn-primary" @click="addEFields">Add more</div>
      <div class="btn-full" @click="updateRole">Update</div>
    </div>
    <div class="temp-row" v-if="state.submitting" style="text-align: center;">
      <img src="@/assets/img/ajldr.gif" style="width:100px"/>
    </div>

  </ModalView>
<!-- end of edit role modal -->
</template>

<script>
import icons from '@/assets/js/icons.js';
import { defineComponent, ref, reactive, onMounted} from 'vue';
import PageHeader from '@/components/PageHeader.vue';
import ModalView from '@/components/ModalView.vue';
// import { useRoute } from 'vue-router';
// import { useStore } from 'vuex';
import call from "../composables/call.js";
import { urls } from "../assets/js/urls";
import { usePermissions } from '@/composables/usePermissions';

export default defineComponent({
  name: 'DepartmentsView',
  components:{PageHeader,ModalView},
  setup(){
    const createData = ref({});
    const editData=ref({});
    const state=reactive({createRoleModal:false,proles:[],submitting:false,
      errors:[],success:false,roles:[],loaded:false,editRoleModal:false
    })
    const { hasPermission } = usePermissions();
    const svgIcons = ref(icons);
    const page=reactive({title:"Roles", btn:"Create new",
    desc:"The Roles page gives an overview of all user roles within the system, their permissions, and main responsibilities. Use this page to understand each role's access level and functions. Click on any role to see details and manage permissions as needed"})
    if(!hasPermission('Roles', 'create')){
      page.btn="";
    }
    const createFields = reactive([
      {name:"role_1"}
    ])

    const editFields=reactive([]);

    const initializeFormData = () => {
      call({
        url:urls.role,
        data:{getRoleList:""},
        success:(res)=>{
          state.loaded=true
          console.log("here");
          if(res.status){
            state.proles=res.msg.plist;
            state.roles=res.msg.rlist;
            const data = {};
            createFields.forEach(set => {
                data[set.name] =  '';
                data[set.name+"_r"]=true;
                data[set.name+"_e"]=false;
                data[set.name+"_c"]=false;
                data[set.name+"_d"]=false;
            });
            createData.value = data; // Update formData
            createData.value['role']=""
          }else{
            alert(res.msg);
          }
        }
      })
    };

    function addCFields(){
      var nn="role_"+(createFields.length + 1).toString();
      createFields.push({name:nn})
      createData.value[nn+"_r"]=true
      createData.value[nn+"_e"]=false;
      createData.value[nn+"_c"]=false;
      createData.value[nn+"_d"]=false;
    }

    function createNewRole(){
      state.createRoleModal=true
      state.submitting=false,
      state.success=false; 
      const data = {};
      createFields.forEach(set => {
          data[set.name] =  '';
          data[set.name+"_r"]=true;
          data[set.name+"_e"]=false;
          data[set.name+"_c"]=false;
          data[set.name+"_d"]=false;
      });
      createData.value = data;
      createData.value['role']=""
    }

    // Initialize form data on mount
    onMounted(initializeFormData);

    function createRole(){
      state.submitting=true;
      state.errors=[];
      var data=createData.value;
      data.createRole=(createFields.length).toString();
      call({
        url:urls.role,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
            state.roles=res.msg;
          }else{
            var error=[res.msg]
            state.errors=error;
          }
        }
      })
    }

    function editRole(i){
      state.success=false;
      state.errors=[];
      var role=state.roles[i];
      editFields.length=0;
      var perms=role.perms;
      const data = {};
      const nData={}
      Object.keys(perms).forEach(key => {
        var ky=parseInt(key)+1;
        var k="role_"+ky
        editFields.push({name:k});
        var p=perms[key]
        data[k] =  p.pid;
        data[k+"_r"]=true;
        data[k+"_e"]=p.e==0 ? false :true;
        data[k+"_c"]=p.c==0 ? false :true;
        data[k+"_d"]=p.d==0 ? false :true;
        data[k+'_pm']=p.pm
        nData[p.label]=p.pid
      })
      editData.value = data; // Update formData
      editData.value['role']=role.name
      editData.value['role_id']=role.id;
      Object.keys(state.proles).forEach(key => {
        Object.keys(state.proles[key].options).forEach(o => {
          var option=state.proles[key].options[o]
          if (nData[option.label] !== undefined) {
            console.log(nData[option.label]," found")
            option.value = nData[option.label];
          }
        })
      })
      state.editRoleModal=true;
    }

    function deletePermission(i){
      var old=editFields[i],
      role_id=editData.value[old.name+"_pm"];
      state.submitting=true;
      call({
        url:urls.role,
        data:{deletePermission:role_id},
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            editFields.splice(i,1)
            i=1;
            var nEditFields=[];
            Object.keys(editFields).forEach(key => {
              console.log(key)
              nEditFields.push({name:"role_"+i});
              i++;
            })
            editFields.length=0
            editFields.push(...nEditFields)
            var nData={}
            i=1;
            Object.keys(editData.value).forEach(key => {
              if(key.substring(0,6)==old.name || key.length > 6){
                return;
              }
              var n="role_"+i;
              nData[n] =  editData.value[key];
              nData[n+"_r"]=editData.value[key+"_r"];
              nData[n+"_e"]=editData.value[key+"_e"];
              nData[n+"_c"]=editData.value[key+"_c"];
              nData[n+"_d"]=editData.value[key+"_d"];
              nData[n+"_pm"]=editData.value[key+"_pm"];
              console.log("setting: "+n)
              i++
            })
            old=editData.value['role'];
            var old_id=editData.value['role_id'];
            editData.value=nData;
            editData.value['role']=old
            editData.value['role_id']=old_id
          }
        }
      })
    }

    function deleteRole(i){
      var role=state.roles[i];
      var c=confirm("Are you sure you want to delete this role ? all "+role.name+" will lose all permissions associated to this role");
      if(!c){return}
      state.loaded=false;
      call({
        url:urls.role,
        data:{deleteRole:role.id},
        success:(res)=>{
          state.loaded=true;
          if(res.status){
            state.roles=res.msg;
          }else{
            alert(res.msg)
          }
        }
      })
    }

    

    function addEFields(){
      var nn="role_"+(editFields.length + 1).toString();
      editFields.push({name:nn})
      editData.value[nn+"_r"]=true
      editData.value[nn+"_e"]=false;
      editData.value[nn+"_c"]=false;
      editData.value[nn+"_d"]=false;
      editData.value[nn+"_pm"]="new";
      console.log(editData)
    }

    function updateRole(){
      state.submitting=true;
      state.errors=[];
      var data=editData.value;
      console.log(data); 
      data.updateRole=(editFields.length).toString();
      call({
        url:urls.role,
        data:data,
        success:(res)=>{
          state.submitting=false;
          if(res.status){
            state.success=true;
            state.roles=res.msg;
          }else{
            state.errors=[res.msg];
          }
        }
      })
    }
    
    return{deleteRole, editData,editFields, editRole,createRole, addCFields,createData,updateRole,
      icons: svgIcons.value, page,createFields,state,addEFields,deletePermission,createNewRole,
      hasPermission}
  }
})
</script>

<style scoped>
.temp-row{padding: 5px 10px;}
.lbl{font-size: 14px; font-weight: bold;}
.temp-row select{background-color: #e8eeef; color: #8a97a0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;}
.chbx{gap: 15px;}
.chbx .bx{display: flex; align-items: center;}
.chbx .bx label{padding-left: 5px; font-weight: normal;}
.btn-full{width: auto; margin: 0; padding: .375rem 24px;}
input[type=checkbox]{cursor: pointer;}
.temp-row.perm{border-bottom: 1px solid var(--background-color);
  padding-bottom: 10px; margin-bottom: 10px;}
.errors{padding: 0px 15px;}
.errors p{background:red; color:white; margin:5px; padding:5px 10px;
    border-radius: 10px; font-weight: bold; font-size: 14px;}
.success p{background: green; color: white; padding: 10px 20px;
    border-radius: 10px; font-weight: bold;}

.roles{margin:20px 0}
.role{display:flex; align-items: center; width: 100%; justify-content: space-between;}
.role > div{flex: 1; padding: 10px;}
.role > div:last-child{display:flex; align-items: center; max-width:100px}
.role:nth-child(odd){background: #ebebeb;}
.ic{height: 30px; fill: red; stroke:red; cursor: pointer; padding: 4px; display: flex; 
  align-items: center; justify-content: center;}

@media screen and (max-width: 700px) {
.role > div:last-child{display:none !important}
.role .mview{display:flex;align-items: center; max-width:100px}
.chbx{gap: 5px; flex-wrap: wrap; font-size: 13px;}
}
</style>
