<template>
    <div>
        <PageHeader :page="page" :template="2" @action="createNewRole" />
        <div class="box v-flex">
            <div class="col-lg-3 col-sm-6 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-header p-2 ps-3">
                    <div class="v-flex justify-content-between">
                        <div>
                        <p class="text-sm mb-0 text-capitalize">Today's Money</p>
                        <h4 class="mb-0">$53k</h4>
                        </div>
                        <div class="iconwrap">
                        <div class="ic"><div v-html="icons.task"></div></div>
                        </div>
                    </div>
                    </div>
                    <hr class="dark horizontal my-0">
                    <div class="card-footer p-2 ps-3">
                    <p class="mb-0 text-sm"><span class="text-success font-weight-bolder">+55% </span>than last week</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-header p-2 ps-3">
                    <div class="v-flex justify-content-between">
                        <div>
                        <p class="text-sm mb-0 text-capitalize">Today's Money</p>
                        <h4 class="mb-0">$53k</h4>
                        </div>
                        <div class="iconwrap">
                        <div class="ic"><div v-html="icons.task"></div></div>
                        </div>
                    </div>
                    </div>
                    <hr class="dark horizontal my-0">
                    <div class="card-footer p-2 ps-3">
                    <p class="mb-0 text-sm"><span class="text-success font-weight-bolder">+55% </span>than last week</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-header p-2 ps-3">
                    <div class="v-flex justify-content-between">
                        <div>
                        <p class="text-sm mb-0 text-capitalize">Today's Money</p>
                        <h4 class="mb-0">$53k</h4>
                        </div>
                        <div class="iconwrap">
                        <div class="ic"><div v-html="icons.task"></div></div>
                        </div>
                    </div>
                    </div>
                    <hr class="dark horizontal my-0">
                    <div class="card-footer p-2 ps-3">
                    <p class="mb-0 text-sm"><span class="text-success font-weight-bolder">+55% </span>than last week</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-header p-2 ps-3">
                    <div class="v-flex justify-content-between">
                        <div>
                        <p class="text-sm mb-0 text-capitalize">Today's Money</p>
                        <h4 class="mb-0">$53k</h4>
                        </div>
                        <div class="iconwrap">
                        <div class="ic"><div v-html="icons.task"></div></div>
                        </div>
                    </div>
                    </div>
                    <hr class="dark horizontal my-0">
                    <div class="card-footer p-2 ps-3">
                    <p class="mb-0 text-sm"><span class="text-success font-weight-bolder">+55% </span>than last week</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import icons from '@/assets/js/icons.js';
import { defineComponent, reactive, ref} from 'vue';
import PageHeader from '@/components/PageHeader.vue';

export default defineComponent({
  name: 'DepartmentsView',
  components:{PageHeader},
  setup(){
    const svgIcons = ref(icons);
    const page=reactive({title:"Task Overview", btn:"Create new",
    desc:"All your task in one view"})

    return{page,icons: svgIcons.value}
  }
})

</script>   

<style scoped>
p{color:#737373 !important; font-family: Inter, Helvetica, Arial, sans-serif;}
.box > *{padding: 0 calc(1.5rem * 0.5);}
.box .card{background:var(--white-color); border-radius: 0.5rem}
.card-header:first-child {border-radius: 0.5rem 0.5rem 0 0;padding: 0.5rem !important;
    padding-left: 1rem !important;background-color: var(--white-color);
    border-bottom: 0 solid #e5e5e5;}
.iconwrap{border-radius: 0.5rem; width: 48px;height: 48px; background-position: center;
    background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
    text-align: center !important; display: flex; align-items: center; justify-content: center;}
.iconwrap .ic{width:18px; height:18px; fill:var(--white-color);line-height: 0em;}
.text-sm {font-size: 0.875rem !important; line-height: 1.5;}
.text-capitalize {text-transform: capitalize !important;}
h4{font-size: 1.5rem; line-height: 1.375; font-weight: 600;}
hr.horizontal.dark {background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);}
.my-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
hr.horizontal {background-color: transparent;}
hr {border-top: none; height: 1px;}
hr {color: inherit; border: 0; opacity: 0.25;}
.ps-3 {padding-left: 1rem !important;}
.p-2 {padding: 0.5rem !important;}
</style>